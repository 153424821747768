import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from "react-router-dom";
import { router } from "./navigation";
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import store from './store';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe("pk_test_81GMwIvUI5L3mOeHw0DO1fx100giw0vSu3");
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Elements stripe={stripePromise}>
        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
          <RouterProvider router={router} />
        </div>
      </Elements>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
