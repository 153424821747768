export const changeMode = (mode) => {
    return {
        type: "CHANGE",
        payload: {
            mode
        }
    }
}

export const addItemToCart = (data) => {
    return {
        type: "ADD_TO_CART",
        payload: {
            data
        }
    }
}

export const removeFromCart = (id) => {
    return {
        type: "REMOVE_FROM_CART",
        payload: {
            id
        }
    }
}

export const emptyCart = (data) => {
    return {
        type: "EMPTY_CART",
        payload: data
    }
}

export const addTasteProfile = (data) => {
    return {
        type: "ADD_TASTE_PROFILE",
        payload: {
            data
        }
    }
}

export const addToFavorites = (data) => {
    return {
        type: "ADD_TO_FAVORTIES",
        payload: {
            data
        }
    }
}

export const adminInfo = (data) => {
    return {
        type: "ADMIN",
        payload: {
            data
        }
    }
}

export const orderMode = (data) => {
    return {
        type: "ORDER_MODE",
        payload: {
            data
        }
    }
}

export const setAdminMode = (data) => {
    return {
        type: "SET_ADMIN_MODE",
        payload: {
            data
        }
    }
}

export const setCartEmpty = () => {
    return {
        type: "SET_CART_EMPTY"
    }
} 