import * as React from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import { Avatar, Box, TextField } from '@mui/material';
import { getLabel, getProductLabel } from "../../../../apis/common";
import { useEffect } from "react";

export default function Review({ data, setInstruction, orderMode }) {
  const [bill, setBill] = React.useState(0);

  const [productLabels, setProductLabels] = React.useState([]);

  const getTranslatedProductLabel2 = (object, product_id, type, default_text) => {
    console.log('getTranslatedLabel2 called with:', { object, product_id, type, default_text });

    if (!productLabels) {
      console.log('getTranslatedLabel2 productLabels is null');
      return default_text;
    }

    if (!object || !product_id) {
      console.log('getTranslatedLabel2 object or product_id is null');
      return default_text;
    }

    console.log('getTranslatedLabel2 productLabels:', productLabels);
    const languageItem = productLabels.find((obj) =>  obj.object === object && obj.objectId === product_id && obj.type === type);
    console.log('getTranslatedLabel2 languageItem:', languageItem);
    const result = languageItem ? languageItem.text : default_text;
    console.log('getTranslatedLabel2 result:', result);
    return result;
    
  };

  const getTranslatedProductLabel = (languageKey) => {
    const languageItem = productLabels.find((obj) => obj.base_text === languageKey);
    return languageItem ? languageItem.text : '';
  };

  useEffect(() => {
    const fetchProductLabel = async () => {
      try {
        const storedLanguage = localStorage.getItem('selectedLanguage');
        const response = await getProductLabel(storedLanguage || '');
        if (response.code === 200) {
          console.log('fetchProductLabel response:', response);
          setProductLabels(response.result);
        }
      } catch (error) {
        console.log('Error fetching language data:', error);
      }
    };

    fetchProductLabel()

  }, [])

  const [labels, setLabels] = React.useState([]);
  const getTranslatedLabel = (languageKey, defaultText='') => {
    const languageItem = labels.find((obj) => obj.language_key === languageKey);
    return languageItem ? languageItem.translated_language : defaultText;
  };
  React.useEffect(() => {
    const fetchLabel = async () => {
      try {
        const storedLanguage = localStorage.getItem('selectedLanguage');
        const response = await getLabel(storedLanguage || '');
        if (response.code === 200) {
          setLabels(response.result);
        }
      } catch (error) {
        console.log('Error fetching language data:', error);
      }
    };

    fetchLabel()

  }, [])

  React.useEffect(() => {
    let sum = 0;
    for (let item of data.cart) {
      sum += item.price * item.quantity;
      if (item.addons.length !== 0) {
        let addons = item.addons?.reduce((accumulator, object) => {
          return accumulator + object.price;
        }, 0);
        sum += addons * item.quantity;
      }
      if (item.variants.length !== 0) {
        let variants = item.variants?.reduce((accumulator, object) => {
          return accumulator + object.price;
        }, 0);
        sum += variants * item.quantity;
      }
    }
    setBill(sum);
  }, [])

  const getAddonsText = (product) => {
    if (product.addons.length) return `[ ${product.addons.map((x) => ` ${x.title} (€${(x.price).toFixed(2)})`)} ]`;
    return ""
  }

  const getVariantText = (product) => {
    if (product.variants.length) return `[ ${product.variants.map((x) => ` ${x.title} (€${(x.price).toFixed(2)})`)} ]`;
    return ""
  }

  const getAddonsAndVariantsText = (product) => {

    console.log('getAddonsAndVariantsText called with:', product);

    // Combine both addons and variants into one array
    //add "type" to each item to distinguish between addons and variants
    for (let item of product.addons) {
      item.type = 'addons';
    }
    for (let item of product.variants) {
      item.type = 'variant';
    }

    const combinedItems = [...product.addons, ...product.variants];

    //need to get the translated label for each item, we need to distinguish between addons and variants
    //get the translated label for each item
    for (let item of combinedItems) {
      console.log("checking item:", item);
      if (item.type == 'addons') {
        item.title = getTranslatedProductLabel2('addons', item.addon_id, null, item.title);
        console.log("item.title:", item.title);
      } else if (item.type == 'variant') {
        item.title = getTranslatedProductLabel2('variant', item.variant_id, null, item.title);
        console.log("item.title:", item.title);
      }
    }
    
    // Map over the combined array to format the text
    const formattedItems = combinedItems.map(x => `${x.title} (€${x.price.toFixed(2)})`);
  
    // Join the formatted items with a comma and space
    return formattedItems.join(', ');
  };

  const computeRowTotal = (item) => {
    let sum = 0;
    sum += item.price * item.quantity;
    if (item.addons.length !== 0) {
      let addons = item.addons?.reduce((accumulator, object) => {
        return accumulator + object.price;
      }, 0);
      sum += addons * item.quantity;;
    }
    if (item.variants.length !== 0) {
      let variants = item.variants?.reduce((accumulator, object) => {
        return accumulator + object.price;
      }, 0);
      sum += variants * item.quantity;;
    }
    return sum;
  }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {getTranslatedLabel('order_summary')}
      </Typography>
      <List disablePadding>

        {data?.cart?.map((product, key) => {
          // Define productTitle within the map callback function, before returning JSX.
          const productTitle = getTranslatedProductLabel2('product', product.product_id, 'title', product.title);

          return (
            <ListItem key={key} sx={{ py: 1, px: 0 }}>
              <Avatar alt="Remy Sharp" src={product.image} />
              <ListItemText 
                sx={{ ml: 1 }} 
                primary={`${productTitle}  (€${product.price.toFixed(2)})   x${product.quantity}`} 
                secondary={`${getAddonsAndVariantsText(product)}`} 
              />

              <div style={{ width: "25%", display: "flex", justifyContent: "flex-end", flexDirection: 'row', flexWrap: "wrap", alignContent: "flex-end", marginLeft: 10 }}>
                {(product.addons.length > 0 || product.variants.length > 0)
                  ? <Typography variant="body2">{`€${(product.quantity * product.price).toFixed(2)}`}</Typography>
                  : <Typography variant="body2" sx={{ fontWeight: "bold" }}>{`€${(product.quantity * product.price).toFixed(2)}`}</Typography>}
                {product.addons.length > 0 && <Typography sx={{ ml: 0.5 }} variant="body2">{`+ €${((product.addons?.reduce((accumulator, object) => { return accumulator + object.price; }, 0)) * product.quantity).toFixed(2)}`}</Typography>}
                {product.variants.length > 0 && <Typography sx={{ ml: 0.5 }} variant="body2">{`+ €${((product.variants?.reduce((accumulator, object) => { return accumulator + object.price; }, 0)) * product.quantity).toFixed(2)}`}</Typography>}
                {(product.addons.length > 0 || product.variants.length > 0) && <Typography variant="body2" sx={{ ml: 0.5, fontWeight: "bold" }}>{` = €${(computeRowTotal(product)).toFixed(2)}`}</Typography>}
              </div>
            </ListItem>
          );
        })}


        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary={`${getTranslatedLabel('total')}:`} />
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
            {`€${bill.toFixed(2)}`}
          </Typography>
        </ListItem>
      </List>
      <Grid container spacing={1}>
        {
          data?.address &&

          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
              {getTranslatedLabel('billing_address')}
            </Typography>
            <Typography gutterBottom>{data.address?.first + " " + data.address?.last}</Typography>
            <Typography gutterBottom>{data.address?.address_1 + ", " + data.address?.address_2 + ", " + data.address?.city + ", " + data.address?.state + ", " + data.address?.zip + ", " + data.address?.country}</Typography>
          </Grid>
        }
        {
          data?.card &&
          <Grid item container direction="column" xs={12} sm={6}>
            <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
              {getTranslatedLabel('payment_details')}
            </Typography>
            <Grid container>
              <React.Fragment key={data?.card?.name || ''}>
                <Grid item xs={6}>
                  <Typography gutterBottom>{data?.card?.name || ''}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>{""}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>{data?.card?.number || ''}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>{data?.card?.exp || ''}</Typography>
                </Grid>
              </React.Fragment>
            </Grid>
          </Grid>
        }
      </Grid>
      {
      //data?.card &&
      orderMode == 2 &&
      <Box sx={{ mt: 3, flex: 1 }}>
        <Typography gutterBottom>{`${getTranslatedLabel('custom_instructions', 'Name und Stellplatz')}:`}</Typography>
        <TextField
          id="instructions"
          name="instructions"
          fullWidth
          autoComplete="shipping address-line2"
          variant="outlined"
          multiline
          placeholder=''
          required
          onChange={(e) => { setInstruction(e.target.value) }}
        />
      </Box>
      }
    </React.Fragment>
  );
}
