import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ShoppingBag from '@mui/icons-material/ShoppingBag';
import InputBase from '@mui/material/InputBase';
import DrawerNavigation from '../../navigation/DrawerNavigation';
import { CartCard } from '../Home';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../routes';
import { useDispatch, useSelector } from 'react-redux'
import { getBusinessInfo } from '../../apis';
import { getLabel, getUserLanguage } from '../../apis/common';
import { adminInfo } from '../../store/actions/userActions';
import Cookies from 'js-cookie';

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

function ScrollTop(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

export default function Header(props) {
  const { cart, mode } = useSelector((store) => store);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [cartAnchor, setCartAnchor] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [logo, setLogo] = React.useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isMenuOpen = Boolean(anchorEl);
  const isCartOpen = Boolean(cartAnchor);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [languages, setLanguages] = React.useState([]);
  const [labels, setLabels] = React.useState([]);

  const getTranslatedLabel = (languageKey) => {
    const languageItem = labels.find((obj) => obj.language_key === languageKey);
    return languageItem ? languageItem.translated_language : '';
  };

  React.useEffect(() => {
    async function fetchData() {
      const response = await getBusinessInfo();
      setLogo(response.image);
      // dispatch(setCartEmpty())
      dispatch(adminInfo(response))
    }
    const fetchLanguageData = async () => {
      try {
        const response = await getUserLanguage(); // Replace with your API call
        if (response.code === 200) {
          setLanguages(response.result);
          const storedLanguage = localStorage.getItem('selectedLanguage');

          if (!storedLanguage && response.result.length > 0) {
            const firsLanguage = response.result[0].name;
            setSelectedLanguage(firsLanguage);
            localStorage.setItem('selectedLanguage', firsLanguage);
            Cookies.set('selectedLanguage', firsLanguage, { expires: 7 });
            window.location.reload();
          } else {
            setSelectedLanguage(storedLanguage || '');
          }
        }
      } catch (error) {
        console.log('Error fetching language data:', error);
      }
    };

    fetchData()
    fetchLanguageData()

    const fetchLabel = async () => {
      try {
        const storedLanguage = localStorage.getItem('selectedLanguage');
        const response = await getLabel(storedLanguage || '');
        if (response.code === 200) {
          setLabels(response.result);
        }
      } catch (error) {
        console.log('Error fetching language data:', error);
      }
    };

    fetchLabel()

  }, [])



  const [selectedLanguage, setSelectedLanguage] = React.useState("");
  const handleLanguageChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedLanguage(selectedValue);
    localStorage.setItem('selectedLanguage', selectedValue);
    Cookies.set('selectedLanguage', selectedValue, { expires: 7 });
    window.location.reload();
  };



  const toggleDrawer = (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(!open);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCartMenuOpen = (event) => {
    setCartAnchor(event.currentTarget)
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = (type) => {
    setAnchorEl(null);
    handleMobileMenuClose();
    navigate(`user/${type}`)
  };

  const handleCartClose = () => {
    setCartAnchor(null);
  }

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => { handleMenuClose(0) }}>{getTranslatedLabel('taste_profile')}</MenuItem>
      <MenuItem onClick={() => { handleMenuClose(1) }}>{getTranslatedLabel('order_history')}</MenuItem>
      <MenuItem onClick={() => { handleMenuClose(2) }}>{getTranslatedLabel('favorites')}</MenuItem>
    </Menu>
  );

  const renderCart = (
    <Menu
      anchorEl={cartAnchor}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isCartOpen}
      onClose={handleCartClose}
    >
      <CartCard labels={labels} handleCartClose={handleCartClose} />
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
          onClick={() => { navigate('/checkout/0') }}
        >
          <Badge badgeContent={cart.length} color="error">
            <ShoppingBag />
          </Badge>
        </IconButton>
        <p>Cart</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inh,erit"
        >
          <AccountCircle />
        </IconButton>
        <p>{getTranslatedLabel('profile')}</p>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <React.Fragment>
        <DrawerNavigation open={open} toggleDrawer={toggleDrawer} logo={logo} />
        <CssBaseline />
        {/* <HideOnScroll {...props}> */}
          <AppBar>
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="open drawer"
                sx={{ mr: 2 }}
                onClick={toggleDrawer}
              >
                <MenuIcon />
              </IconButton>
              <Box
                component="img"
                sx={{ height: 50, cursor: "pointer" }}
                alt="Logo"
                src={logo}
                onClick={() => { navigate(ROUTES.COMMON.HOME) }}
              />
              {/* <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ 'aria-label': 'search' }}
                />
              </Search> */}
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1">
                  Language :
                </Typography>
                <Select
                  value={selectedLanguage}
                  onChange={handleLanguageChange}
                  variant="outlined"
                  displayEmpty
                  inputProps={{ 'aria-label': 'language' }}
                  sx={{
                    color: 'white',
                    '& .MuiSelect-select': {
                      '&:focus': {
                        backgroundColor: 'transparent',
                      },
                    },
                  }}
                >
                  {languages.map((language) => (
                    <MenuItem key={language.name} value={language.name}>
                      {language.name}
                    </MenuItem>
                  ))}
                  {/* Add more language options as needed */}
                </Select>
              </Box>

              {
                mode === "user" ?
                  <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                    <IconButton
                      size="large"
                      aria-label="show 17 new notifications"
                      color="inherit"
                      onClick={handleCartMenuOpen}
                    >
                      {
                        cart.length !== 0 ?
                          <Badge badgeContent={cart.length} color="error">
                            <ShoppingBag />
                          </Badge> : <ShoppingBag />
                      }
                    </IconButton>
                  </Box> : <></>
              }
              <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                {
                  mode === "user" ?

                    <IconButton
                      size="large"
                      aria-label="show 17 new notifications"
                      color="inherit"
                      onClick={handleCartMenuOpen}
                    >
                      {
                        cart.length !== 0 ?
                          <Badge badgeContent={cart.length} color="error">
                            <ShoppingBag />
                          </Badge> : <ShoppingBag />
                      }
                    </IconButton>
                    : <></>
                }
              </Box>
            </Toolbar>
          </AppBar>
        {/* </HideOnScroll> */}
        <Toolbar id="back-to-top-anchor" />
        {/* {renderMobileMenu} */}
        {/* {renderMenu} */}
        {renderCart}
        <ScrollTop {...props}>
          <Fab size="small" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>
      </React.Fragment>
    </>
  );
}
