import * as React from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';

export default function NumberPicker({ value, setValue, price, setPrice, productPrice }) {
    return (
        <ButtonGroup
            disableElevation
            variant="contained"
            aria-label="Disabled elevation buttons"
            sx={{ height: 25 }}
        >
            <Button size='small' sx={{ width: 8 }} onClick={() => { setValue(value - 1); setPrice(Math.abs(price - productPrice)) }} disabled={value === 0}><Typography sx={{ fontSize: 20 }}>−</Typography></Button>
            <TextField inputProps={{style: {height: 8}}} id="outlined-basic" size="small" variant="outlined" sx={{ width: 50, height: '5px' }} value={value} />
            <Button size='small' sx={{ width: 6 }} onClick={() => { setValue(value + 1); setPrice(price + productPrice) }} disabled={value === 20} ><Typography sx={{ fontSize: 20 }}>+</Typography></Button>
        </ButtonGroup>
    );
}