import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Avatar, Button, CardHeader, CircularProgress, FormControl, IconButton, InputLabel, ListItem, ListItemText, MenuItem, Select } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/system';
import { updateStatus } from '../../../apis/admin';

export default function OrderDetails({ data, bill, setData, closeBackdrop, change, setChange, setText, setOpenSucess, setOpenFailed }) {
  const [status, setStatus] = React.useState(data?.status || "Pending");
  const [loading, setLoading] = React.useState(false);

  const computeTotal = (orderline) => {
    let sum = orderline.Product?.price * orderline.quantity;
    for (let addon of orderline.OrderAddons) {
      sum += addon.AddOn?.price * orderline.quantity;
    }
    if (orderline.Variant) {
      sum += orderline.Variant?.price * orderline.quantity;
    }
    return sum.toFixed(2);
  }

  React.useEffect(() => {
    setStatus(data?.status || "Pending")
  }, [data])

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const response = await updateStatus(data?.order_id, status)
    if (response.code === 200) {
      setText("Order status successfully updated!");
      setOpenSucess(true);
    } else {
      setText("There was an error while updating the order status!");
      setOpenFailed(true);
    }
    setLoading(false);
    setChange(!change);
    closeBackdrop();
  }

  return (
    <Card sx={{ width: 500, minHeight: 500, padding: 1, overflowY: "scroll" }}>
      <CardHeader
        action={
          <IconButton aria-label="settings"
            onClick={closeBackdrop}
          >
            <CloseIcon sx={{ alignSelf: "flex-end" }} />
          </IconButton>
        }
        title={"Order details"}
        sx={{ color: "black" }}
      />
      <CardContent>
        <div style={{ display: "flex", flexDirection: "row", flex: 1, justifyContent: "space-between", alignItems: "center", marginBottom: 20 }}>
          <Typography sx={{ fontWeight: "bold" }} color="text.primary">
            {`Order ID: ${data?.order_id}`}
          </Typography>
          <Typography variant="body2">
            {`${new Date(data?.datetime).toLocaleString()}`}
          </Typography>
        </div>
        <Typography color="text.primary">
          {`Mode: ${data?.mode}`}
        </Typography>
        <Typography variant="body2">
          {`${data?.modeInfo?.replace(", Name: undefined", "")}`}
        </Typography>
        <Typography sx={{ fontWeight: "bold", mt: 2 }} color="text.primary">
          {`Items:`}
        </Typography>
        {data?.Orderlines?.map((product, key) => (
          <div>
            <ListItem key={key} sx={{ py: 1, px: 0 }}>
              <Avatar sx={{ width: 50, height: 50 }} variant="rounded" alt="Remy Sharp" src={product?.Product?.image} />
              <ListItemText sx={{ ml: 1 }} primary={`${product?.Product?.title}  (€${product?.Product?.price})  x${product.quantity}`} secondary={product.description} />
              <Typography variant="body2">{`€${computeTotal(product)}`}</Typography>
            </ListItem>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <text style={{ marginTop: 15, marginRight: 10 }}>Variant: </text>
              {
                product?.OrderVariants?.length === 0 ? <text style={{ marginTop: 15, marginRight: 10 }}>N/A </text> :
                  product?.OrderVariants?.map((addon, key) => {
                    return (
                      <div style={{ width: 60, display: "flex", alignItems: "center", flexDirection: "column" }}>
                        <Avatar alt={addon.Variant.title} src={addon?.Variant?.image} variant="circular" sx={{ width: 40, height: 40, borderImageOutset: 5, border: addon.selected ? '5px solid #1565C0' : 'none' }} />
                        <Typography variant="body2" sx={{ textAlign: "center", color: "text.secondary" }}>{addon.Variant?.title}</Typography>
                        <Typography variant="body2" sx={{ textAlign: "center", color: addon.selected ? "#1565C0" : "text.secondary" }}>{`€${addon.Variant?.price}`}</Typography>
                      </div>
                    );
                  })
              }
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <text style={{ marginTop: 15, marginRight: 10 }}>Addons: </text>
              {
                product?.OrderAddons?.length === 0 ? <text style={{ marginTop: 15, marginRight: 10 }}>N/A </text> :
                  product?.OrderAddons?.map((addon, key) => {
                    return (
                      <div style={{ width: 60, display: "flex", alignItems: "center", flexDirection: "column" }}>
                        <Avatar alt={addon.title} src={addon?.AddOn?.image} variant="circular" sx={{ width: 40, height: 40, borderImageOutset: 5, border: addon.selected ? '5px solid #1565C0' : 'none' }} />
                        <Typography variant="body2" sx={{ textAlign: "center", color: "text.secondary" }}>{addon.AddOn?.title}</Typography>
                        <Typography variant="body2" sx={{ textAlign: "center", color: addon.selected ? "#1565C0" : "text.secondary" }}>{`€${addon.AddOn?.price}`}</Typography>
                      </div>
                    );
                  })
              }
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <text style={{ marginTop: 15, marginRight: 10 }}>Ingredients to remove: </text>
              {
                product?.OrderIngredients?.length === 0 ? <text style={{ marginTop: 15, marginRight: 10 }}>N/A </text> :
                  product?.OrderIngredients?.map((ingredient, key) => {
                    return (
                      <div style={{ width: 70, display: "flex", alignItems: "center", flexDirection: "column" }}>
                        <Avatar alt={ingredient.title} src={ingredient?.Ingredient?.image} variant="circular" sx={{ width: 40, height: 40, borderImageOutset: 5, border: ingredient.selected ? '5px solid #1565C0' : 'none' }} />
                        <Typography variant="body2" sx={{ textAlign: "center", color: "text.secondary" }}>{ingredient.Ingredient?.title}</Typography>
                      </div>
                    );
                  })
              }
            </div>
            <Box sx={{ borderBottom: 1, mt: 1, mb: 1, borderBottomColor: "text.secondary" }} />
          </div>
        ))}
        {
          data?.instructions && <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="body" sx={{ color: "text.primary", fontWeight: "bold", fontSize: 18 }}>Custom Instructions:  </Typography>
            <Typography variant="body">{` ${data?.instructions}`}</Typography>
          </Box>
        }
        <Box component="form" onSubmit={handleSubmit}>
          <div style={{ marginTop: 20, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>

            <FormControl sx={{ width: "60%" }}>
              <InputLabel id="demo-simple-select-label">Order Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="order_status"
                name='order_status'
                value={status}
                label="Order Status"
                onChange={(e) => { setStatus(e.target.value) }}
              >
                {
                  ["Pending", "Cancelled", "Completed"].map((status, key) => {
                    return (
                      <MenuItem value={status}>{status}</MenuItem>
                    );
                  })
                }
              </Select>
            </FormControl>
            <Typography sx={{ fontWeight: "bold" }} color="text.primary">
              {`Bill: €${data?.bill}`}
            </Typography>
          </div>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {"Update Status"}
            {
              loading && <CircularProgress size={15} color="inherit" sx={{ ml: 1 }} />
            }
          </Button>
        </Box>
      </CardContent>
    </Card >
  );
}