import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { Backdrop, Button, Card, CardHeader, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import image from '../../../assets/images/emptyCart.png'
import store from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { orderMode, removeFromCart } from '../../../store/actions/userActions';
import { useEffect } from "react";
import { getProductLabel } from "../../../apis/common";

function EmptyCart({ labels }) {
  const getTranslatedLabel = (languageKey) => {
    const languageItem = labels.find((obj) => obj.language_key === languageKey);
    return languageItem ? languageItem.translated_language : '';
  };
  return (
    <>
      <Box
        component="img"
        sx={{ height: 250, marginTop: 2 }}
        alt="Logo"
        src={image}
      />
      <Typography sx={{ textAlign: "center" }}>{getTranslatedLabel('your_cart_is_currently_empty')} !</Typography>
    </>
  );
}

export default function CartCard({ handleCartClose, labels }) {

  const [open, setOpen] = React.useState(false);
  //const [table, setTable] = React.useState("");
  const [table, setTable] = React.useState(sessionStorage.getItem('tableNumber') ? sessionStorage.getItem('tableNumber') == "null" ? '' : sessionStorage.getItem('tableNumber') : '');

  const [productLabels, setProductLabels] = React.useState([]);

  const getTranslatedProductLabel2 = (object, product_id, type, default_text) => {
    console.log('getTranslatedLabel2 called with:', { object, product_id, type, default_text });

    if (!productLabels) {
      console.log('getTranslatedLabel2 productLabels is null');
      return default_text;
    }

    if (!object || !product_id) {
      console.log('getTranslatedLabel2 object or product_id is null');
      return default_text;
    }

    console.log('getTranslatedLabel2 productLabels:', productLabels);
    const languageItem = productLabels.find((obj) =>  obj.object === object && obj.objectId === product_id && obj.type === type);
    console.log('getTranslatedLabel2 languageItem:', languageItem);
    const result = languageItem ? languageItem.text : default_text;
    console.log('getTranslatedLabel2 result:', result);
    return result;
    
  };

  const getTranslatedProductLabel = (languageKey) => {
    const languageItem = productLabels.find((obj) => obj.base_text === languageKey);
    return languageItem ? languageItem.text : '';
  };

  useEffect(() => {
    const fetchProductLabel = async () => {
      try {
        const storedLanguage = localStorage.getItem('selectedLanguage');
        const response = await getProductLabel(storedLanguage || '');
        if (response.code === 200) {
          setProductLabels(response.result);
        }
      } catch (error) {
        console.log('Error fetching language data:', error);
      }
    };

    fetchProductLabel()
  }, [])



  const getTranslatedLabel = (languageKey) => {
    const languageItem = labels.find((obj) => obj.language_key === languageKey);
    return languageItem ? languageItem.translated_language : '';
  };
  const [mode, setMode] = React.useState(getTranslatedLabel('eat_here'));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((store) => store.cart)



  const removeItem = (id) => {
    store.dispatch(removeFromCart(id));
  }

  const handleChange = (event) => {
    setMode(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleCartClose();
    setOpen(false);
    dispatch(orderMode({ title: mode, info: { table } }));
    navigate(`/checkout/${mode === getTranslatedLabel('home_delivery') ? 3 : mode === getTranslatedLabel('eat_here') ? 1 : 2}/${mode === getTranslatedLabel('eat_here') ? table : 0}`, { replace: true });
  }


  return (
    <>
      <div style={{ maxHeight: 360 }}>
        <List sx={{ width: 360 }}>
          {
            cart.length === 0 ? <EmptyCart labels={labels} /> :
              cart?.map((item, key) => {
                return (
                  <ListItem
                    alignItems="flex-start"
                    secondaryAction={
                      <IconButton edge="end" aria-label="comments" onClick={() => { removeItem(key) }}>
                        <CloseIcon />
                      </IconButton>
                    }
                  >
                    <ListItemAvatar>
                      <Avatar alt="Remy Sharp" src={item.image} />
                    </ListItemAvatar>
                    <ListItemText
                      // primary={getTranslatedProductLabel(item.title)}
                      primary={getTranslatedProductLabel2('product', item.product_id, 'title', item.title)}
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.secondary"
                          >
                            {`€${item.price} `}
                          </Typography>
                          {` x ${item.quantity} `}
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                );
              })
          }
        </List>
        <Divider variant='middle' />
        <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
          <Button disabled={cart.length === 0} variant="contained" sx={{ alignSelf: "center", flex: 1, margin: 1 }} onClick={() => { /*navigate(ROUTES.USER.CHECKOUT)*/ setOpen(true); }} >{getTranslatedLabel('proceed_to_checkout')}</Button>
        </div>
      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <Card sx={{ width: 400, padding: 2 }}>
          <CardHeader
            action={
              <IconButton aria-label="settings"
                onClick={() => { setOpen(false) }}
              >
                <CloseIcon sx={{ alignSelf: "flex-end" }} />
              </IconButton>
            }
            title={getTranslatedLabel('select_order_mode')}
            sx={{ color: "black", fontWeight: "bold" }}
          />
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">{getTranslatedLabel('type')}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="type"
                name='type'
                value={mode}
                label={getTranslatedLabel('type')}
                onChange={handleChange}
              >
                {
                  [getTranslatedLabel('eat_here'), getTranslatedLabel('take_away')].map((mode, key) => {
                    return (
                      <MenuItem value={mode}>{mode}</MenuItem>
                    );
                  })
                }
              </Select>
              {
                mode === getTranslatedLabel('eat_here') &&
                <TextField
                  value={table}
                  margin="normal"
                  required
                  fullWidth
                  id="title"
                  onChange={(e) => {
                    setTable(e.target.value);
                  }}
                  label={getTranslatedLabel('table')}
                  name="table"
                  autoComplete="table"
                  autoFocus
                />
              }
              <Button type="submit" sx={{ mt: 2, flex: 1 }} size="medium" color="primary" variant='contained'>
                {mode === getTranslatedLabel('eat_here') ? 'confirm' : getTranslatedLabel('proceed')}
              </Button>
            </FormControl>
          </Box>
        </Card>
      </Backdrop>
    </>
  );
}
