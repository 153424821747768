import { Button, CircularProgress, MenuItem, Select, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getSelectedFilename, getTemplates, updateTemplate, uploadTemplate } from "../../../apis/admin";
import FailedToast from "../../../components/common/FailedToast";
import SuccessToast from "../../../components/common/SuccessToast";
import ROUTES from "../../../routes";
import store from "../../../store";

function CssTemplate() {
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState();
    const [file, setFile] = useState();
    const [openSuccess, setOpenSucess] = useState(false);
    const [openFailed, setOpenFailed] = useState(false);
    const [selectedFilename, setSelectedFilename] = useState();

    const navigate = useNavigate();
    useEffect(() => {
        const state = store.getState();
        if (state.mode != "admin") {
            navigate(ROUTES.ADMIN.LOGIN);
        }
        async function fetchData() {
            setTemplates(await getTemplates())
            setSelectedFilename(await getSelectedFilename())
        }
        fetchData();
    }, [])

    const handleTemplateChange = async (event) => {
        const selectedValue = event.target.value;
        setSelectedTemplate(selectedValue);
        const response = await updateTemplate(selectedValue);
        if (response.code == 200) {
            setOpenSucess(true);
        } else {
            setOpenFailed(true);
        }
    }
    
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSucess(false);
        setOpenFailed(false);
    }

    const handleSubmit = async () => {
        if (file) {
            let form = new FormData();
            form.append('file', file);
            const response = await uploadTemplate(form);
            if (response.status === "success") {
                setOpenSucess(true);
            } else {
                setOpenFailed(true);
            }
        }
    }

    return (
        <Box sx={{ width: '90%', marginTop: 8, display: "flex", flexDirection: "column", alignItems: "center", minHeight: `calc(100vh - 10vh)` }}>
            <Typography variant="h4" sx={{ fontFamily: 'Clearface' }}>Select CSS Template for the website:</Typography>
            {
                selectedFilename ? <Select
                    defaultValue={selectedFilename || 'Select Template'}
                    value={selectedTemplate}
                    onChange={handleTemplateChange}
                    variant="outlined"
                    inputProps={{ 'aria-label': 'language' }}
                    sx={{
                        color: 'black',
                        '& .MuiSelect-select': {
                            '&:focus': {
                                backgroundColor: 'transparent',
                            },
                        },
                        mt: 3,
                        width: 250,
                    }}
                >
                    <MenuItem disabled value='Select Template' sx={{ alignSelf: 'center' }}>
                        Select Template
                    </MenuItem>
                    {templates.map((template, index) => (
                        <MenuItem key={index} value={template}>
                            {template.replace(".css", '').replaceAll('_', ' ').trim()}
                        </MenuItem>
                    ))}
                </Select>
                    : <CircularProgress sx={{ mt: 2 }} />
            }

            {/* <Box sx={{ mt: 10 }}>
                <Typography variant="h4" sx={{ fontFamily: 'Clearface', textAlign: 'center' }}>Upload a CSS Template:</Typography>

                <input id="input" type="file" accept=".css" onChange={(e) => { setFile(e.target.files[0]) }} style={{ marginTop: 20, alignSelf: 'center' }} />


                <Button sx={{ width: 100, mt: 2 }} onClick={handleSubmit}>Submit</Button>
            </Box> */}
            <SuccessToast open={openSuccess} handleClose={handleClose} text={"Template successfully updated!"} />
            <FailedToast open={openFailed} handleClose={handleClose} text={"There was an error while updating template!"} />
        </Box >
    );
}

export default CssTemplate;