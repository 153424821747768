import React, { useEffect, useState } from 'react';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Card, CardHeader, CircularProgress, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './styles.scss'
import {
    EditAddonsLanguage,
    updateAddonsLanguage,

} from '../../../apis/admin';

import dummy from '../../../assets/images/dummy.jpeg';

function AddonsLanguageEditCard({ setData, data, closeBackdrop, setChange, change, setText, setOpenFailed, setOpenSucess }) {
    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [loading, setLoading] = useState(false);
    const [arrayData, setArrayData] = useState([]);
    const [isEditMode, setIsEditMode] = useState(false);
    const [languageData, setLanguageData] = useState({
        id: "",
        name: "",
        translations: [],
    });

    useEffect(() => {
        setId(data?.id || "");
        setName(data?.name || "");
        if (data?.name) {
            setIsEditMode(true);
            getEditData();
        }
    }, [data]);

    useEffect(() => {
    }, [languageData]);

    const getEditData = async () => {
        const response = await EditAddonsLanguage(data.name);

        if (response.code === 200) {
            const resData = response.result;

            if (resData.length > 0) {
                const prepareArrayData = () => {
                    const newArrayData = resData.map(obj => {
                        const { id, base_text, text } = obj;
                        return { id, base_text, text };
                    });
                    setArrayData(newArrayData);
                    setLanguageData(prevState => ({
                        ...prevState,
                        translations: newArrayData,
                    }));
                };

                prepareArrayData();
            }
        }
    };

    const handleLanguageChange = (e) => {
        setName(e.target.value);
        setLanguageData(prevState => ({
            ...prevState,
            name: e.target.value,
        }));
    };

    const handleTranslatedLanguageChange = (id, value) => {
        const updatedTranslations = languageData.translations.map(item => {
            if (item.id === id) {
                return {
                    ...item,
                    text: value,
                };
            }
            return item;
        });

        setArrayData(updatedTranslations);
        setLanguageData(prevState => ({
            ...prevState,
            translations: updatedTranslations,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            if (isEditMode) {
                // Update existing language
                const response = await updateAddonsLanguage(languageData);
                // Handle the response and any further actions
                if (response.code === 200) {
                    setText("Language successfully updated!");
                    setOpenSucess(true);
                } else {
                    setText("There was an error while updating the Language!");
                    setOpenFailed(true);
                }
            }

            setChange(!change);
            closeBackdrop();
            setData({});
            setIsEditMode(false);
        } catch (error) {
            // Handle errors
            console.error(error);
        }

        setLoading(false);
    };

    return (
            <Card sx={{ padding: 2, width: "46%", maxHeight: "80vh", overflowY: "auto" }}>
            <CardHeader
                action={
                    <IconButton aria-label="settings" onClick={() => {
                        closeBackdrop();
                        setIsEditMode(false);
                    }}>
                        <CloseIcon sx={{ alignSelf: "flex-end" }} />
                    </IconButton>
                }
                title={data ? "Edit Language" : "Add Language"}
                sx={{ color: "black" }}
            />

            <Container component="main">
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <div style={{ display: "flex", flexDirection: 'column', justifyContent: "space-between", alignItems: "center" }}>
                        {/* Single text field for Language */}
                        <TextField
                            value={name}
                            margin="normal"
                            required
                            fullWidth
                            id="name"
                            onChange={handleLanguageChange}
                            label="Language"
                            name="name"
                            autoComplete="name"
                            autoFocus
                            InputProps={isEditMode ? { readOnly: true } : {}}
                        />
                        {/* Dynamically render text fields for each item in arrayData */}
                        {isEditMode && arrayData.map(item => (
                            <TextField
                                key={item.id}
                                value={item.text}
                                margin="normal"
                                required
                                fullWidth
                                id={`translate_language_${item.id}`}
                                onChange={(e) => handleTranslatedLanguageChange(item.id, e.target.value)}
                                label={item.base_text}
                                name={`translate_language_${item.id}`}
                                autoComplete={`translate_language_${item.id}`}
                            />
                        ))}
                    </div>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {data ? "Update Info" : "Add Language"}
                        {loading && <CircularProgress size={15} color="inherit" sx={{ ml: 1 }} />}
                    </Button>
                </Box>
            </Container>
        </Card>
    );
}

export default AddonsLanguageEditCard;
