import React, { useEffect } from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import ROUTES from "../../routes";
import { useNavigate } from "react-router-dom";

function TransactionFailed() {
    const navigate = useNavigate();
    useEffect(() => {
        setTimeout(() => {
            navigate(ROUTES.COMMON.HOME)
        }, 2000)
    }, [])
    return (
        <Box style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', justifyContent: 'center', marginTop: 120 }}>
            <CancelRoundedIcon style={{ color: 'red', fontSize: 250, alignSelf: 'center' }} />
            <Typography style={{ color: 'red', fontSize: 20, textAlign: "center", marginTop: 20 }}>{"Transaction Failed"}</Typography>
            <Typography style={{ fontSize: 15, textAlign: "center", marginTop: 10 }}>{"Redirecting back to menu..."}</Typography>
        </Box>
    );
}

export default TransactionFailed;