import React, { useEffect, useState } from 'react';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Card, CardHeader, CircularProgress, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './styles.scss'
import { addLanguageKey, updateLanguageKey } from '../../../apis/admin';
import dummy from '../../../assets/images/dummy.jpeg';

function LanguageKeyEditCard({ setData, data, closeBackdrop, setChange, change, setText, setOpenFailed, setOpenSucess }) {
    const [key, setKey] = useState("")
    const [value, setValue] = useState("")
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setKey(data?.key || "");
        setValue(data?.value || "");
    }, [data])

    const handleSubmit = async (event) => {
        event.preventDefault();
        const updatedLanguage = {
            id: data?.id,
            key: event.currentTarget.key.value,
            value: event.currentTarget.value.value,
        };

        if (data) {
            setLoading(true);
            const response = await updateLanguageKey(updatedLanguage);
            setLoading(false);
            if (response.code === 200) {
                setText("Language key successfully updated!");
                setOpenSucess(true);
            } else {
                setText("There was an error while updating the Language key!");
                setOpenFailed(true);
            }
        } else {
            setLoading(true);
            const response = await addLanguageKey({ key, value });
            setLoading(false);
            if (response.code === 200) {
                setText("Language key successfully added!");
                setOpenSucess(true);
            } else {
                setText("There was an error while adding the Language key!");
                setOpenFailed(true);
            }
        }

        setChange(!change);
        closeBackdrop();
        setData({});
    };


    return (
        <Card sx={{ padding: 2, width: "46%" }}>
            <CardHeader
                action={
                    <IconButton aria-label="settings"
                        onClick={closeBackdrop}
                    >
                        <CloseIcon sx={{ alignSelf: "flex-end" }} />
                    </IconButton>
                }
                title={data ? "Edit Language key" : "Add Language key"}
                sx={{ color: "black" }}
            />

            <Container component="main">
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <div style={{ display: "flex", flexDirection: 'column', justifyContent: "space-between", alignItems: "center" }}>
                        {/*<div>*/}
                        <TextField
                            value={key}
                            margin="normal"
                            required
                            fullWidth
                            id="key"
                            label="Key"
                            name="key"
                            autoComplete="off"
                            autoFocus
                            onChange={(e) => {
                                setKey(e.target.value);
                            }}

                        />

                        <TextField
                            value={value}
                            margin="normal"
                            required
                            fullWidth
                            id="value"
                            label="Value"
                            name="value"
                            autoComplete="off"
                            autoFocus
                            onChange={(e) => {
                                setValue(e.target.value);
                            }}
                        />

                        {/*</div>*/}
                    </div>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {data ? "Update Info" : "Submit"}
                        {
                            loading && <CircularProgress size={15} color="inherit" sx={{ml: 1}}/>
                        }
                    </Button>
                </Box>
            </Container>
        </Card >
    );
}

export default LanguageKeyEditCard;
