import { axios, base_url } from "./imports";

export async function getSubCategories(id) {
    const response = await axios.get(base_url + `subcategories/${id}`);
    return response.data.result;
}

export async function getProducts(id, nutritions, allergens) {
    console.log(nutritions, allergens)
    const response = await axios.get(base_url + `getProductsByCategory/${id}`);
    const products = response.data.result;
    let filteredProducts = []
    if (nutritions.length > 0) {
        for (let product of products) {
            for (let tp of product.TasteProfiles) {
                if (nutritions.includes(tp.title)) {
                    filteredProducts.push(product)
                }
            }
        }
    } else {
        filteredProducts = products;
    }

    if (allergens.length > 0) {
        for (let x = 0; x < filteredProducts.length; x++) {
            let check = false;
            for (let tp of filteredProducts[x].TasteProfiles) {
                if (allergens.includes(tp.title)) {
                    check = true;
                }
            }
            if (check) {
                filteredProducts.splice(x, 1)
            }
        }
    }

    return filteredProducts;
}

export async function getFavorites(arr) {
    const response = await axios.post(base_url + `getSelectedProducts`, { ids: arr });
    return response.data.result;
}

export async function placeOrder(order) {
    const response = await axios.post(base_url + `addOrder`, order);
    console.log(response.data);
    return response.data.result;
}

export async function makePayment(details) {
    const response = await axios.post(base_url + `pay`, details);
    return response.data;
}