import React, { useEffect, useState } from 'react';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Card, CardHeader, CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Select } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { addTasteProfile, updateTasteProfile } from '../../../apis';
import dummy from '../../../assets/images/dummy.jpeg'
import './styles.scss'

function TasteProfileEditCard({ setData, data, closeBackdrop, setChange, change, setText, setOpenFailed, setOpenSucess }) {
    const [title, setTitle] = useState("");
    const [file, setFile] = useState();
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState("Nutrition");

    useEffect(() => {
        setTitle(data?.title || "");
        setType("Nutrition")
        setFile();
    }, [data])

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data_ = new FormData(event.currentTarget);
        const updatedAddon = {
            id: data?.tasteProfile_id,
            title: data_.get("title"),
            type: data_.get("type"),
            file: file
        };

        let form = new FormData();
        form.append('title', updatedAddon.title);
        form.append('type', updatedAddon.type);
        form.append('file', file)

        if (data) {
            setLoading(true);
            const response = await updateTasteProfile(updatedAddon.id, form);
            setLoading(false);
            if (response.code === 200) {
                setText("Taste Profile successfully updated!");
                setOpenSucess(true);
            } else {
                setText("There was an error while updating the Taste Profile!");
                setOpenFailed(true);
            }
        } else {
            setLoading(true);
            const response = await addTasteProfile(form);
            setLoading(false);
            if (response.code === 200) {
                setText("Taste Profile successfully added!");
                setOpenSucess(true);
            } else {
                setText("There was an error while adding the Taste Profile!");
                setOpenFailed(true);
            }
        }

        setChange(!change);
        closeBackdrop();
        setData({})
    };

    const handleChange = (event) => {
        setType(event.target.value);
    };

    return (
        <Card sx={{ padding: 2, width: "46%" }}>
            <CardHeader
                action={
                    <IconButton aria-label="settings"
                        onClick={closeBackdrop}
                    >
                        <CloseIcon sx={{ alignSelf: "flex-end" }} />
                    </IconButton>
                }
                title={data ? "Edit Taste Profile" : "Add new Taste Profile"}
                sx={{ color: "black" }}
            />

            <Container component="main">
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <div style={{ display: "flex", flexDirection: 'row', justifyContent: "space-between", alignItems: "center" }}>
                        <div>
                            <TextField
                                value={title}
                                margin="normal"
                                required
                                fullWidth
                                id="title"
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                }}
                                label="Title"
                                name="title"
                                autoComplete="title"
                                autoFocus
                            />
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="type"
                                    name='type'
                                    value={type}
                                    label="Type"
                                    onChange={handleChange}
                                >
                                    {
                                        ["Allergen", "Nutrition"].map((type, key) => {
                                            return (
                                                <MenuItem value={type}>{type}</MenuItem>
                                            );
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        <Box style={{ display: "flex", flexDirection: "column", width: 230 }}>
                            <div className="carousel__slide-item carousel__slide-item-img-link" onClick={() => {
                                document.querySelector('input[id="image_input"]').click();
                            }}>
                                <input
                                    type="file"
                                    hidden
                                    id="image_input"
                                    onChange={(e) => { setFile(e.target.files[0]) }}
                                    style={{ display: "none" }}
                                />
                                <img
                                    component="img"
                                    style={{ height: 230, width: 230, objectFit: "cover", borderRadius: 10 }}
                                    alt="Logo"
                                    src={file ? URL.createObjectURL(file) : data?.image ? data?.image : dummy}
                                />
                            </div>
                        </Box>
                    </div>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {data ? "Update info" : "Add Taste Profile"}
                        {
                            loading && <CircularProgress size={15} color="inherit" sx={{ ml: 1 }} />
                        }
                    </Button>
                </Box>
            </Container>
        </Card >
    );
}

export default TasteProfileEditCard;
