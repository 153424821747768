import { Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

export default function About() {
    const info = useSelector((store) => store.info)
    return (
        <>
            <Typography variant='h3' sx={{ marginTop: 10, alignSelf: "left", fontWeight: "bold" }}>About</Typography>
            <Typography sx={{ width: "80%", mt: 4, textAlign: "center" }}>{info.about}</Typography>
        </>
    );
}

