import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { getLabel } from "../../../../apis/common";
import {
  useStripe, useElements,
  CardNumberElement, CardExpiryElement, CardCvcElement, CardElement
} from '@stripe/react-stripe-js';

export default function PaymentForm({ state, setState }) {
  const [labels, setLabels] = React.useState([]);


  const getTranslatedLabel = (languageKey) => {
    const languageItem = labels.find((obj) => obj.language_key === languageKey);
    return languageItem ? languageItem.translated_language : '';
  };
  React.useEffect(() => {
    const fetchLabel = async () => {
      try {
        const storedLanguage = localStorage.getItem('selectedLanguage');
        const response = await getLabel(storedLanguage || '');
        if (response.code === 200) {
          setLabels(response.result);
        }
      } catch (error) {
        console.log('Error fetching language data:', error);
      }
    };

    fetchLabel()

  }, [])
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {getTranslatedLabel('payment_method')}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="cardName"
            label={getTranslatedLabel('name_on_card')}
            fullWidth
            autoComplete="cc-name"
            variant="standard"
            onChange={(e) => { setState({ ...state, "name": e.target.value }) }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="cardEmail"
            label={"Email"}
            fullWidth
            autoComplete="cc-name"
            variant="standard"
            onChange={(e) => { setState({ ...state, "email": e.target.value }) }}
          />
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <TextField
            required
            id="cardNumber"
            label={getTranslatedLabel('card_number')}
            fullWidth
            autoComplete="cc-number"
            variant="standard"
            onChange={(e) => { setState({ ...state, "number": e.target.value }) }}
            InputProps={{
              inputProps: {
                component: CardNumberElement,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="expDate"
            label={getTranslatedLabel('expiry_date')}
            fullWidth
            autoComplete="cc-exp"
            variant="standard"
            onChange={(e) => { setState({ ...state, "exp": e.target.value }) }}
            InputProps={{
              inputProps: {
                component: CardExpiryElement,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="cvv"
            label={getTranslatedLabel('CVV')}
            helperText={getTranslatedLabel('cvv_helper')}
            fullWidth
            autoComplete="cc-csc"
            variant="standard"
            onChange={(e) => { setState({ ...state, "cvv": e.target.value }) }}
            InputProps={{
              inputProps: {
                component: CardCvcElement,
              },
            }}
          />
        </Grid> */}
        {/* <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="secondary" name="saveCard" value="yes" />}
            label={getTranslatedLabel('remember_card')}
          />
        </Grid> */}
      </Grid>
    </React.Fragment>
  );
}
