const initialState = {
    mode: 'user',
    cart: [],
    info: {},
    orderMode: {},
    isSuper: false
}

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case "CHANGE":
            {
                return {
                    ...state,
                    mode: action.payload.mode
                }
            }

        case "ADD_TO_CART":
            {
                let index = state.cart.findIndex((item)=> JSON.stringify({...item, quantity: 0}) === JSON.stringify({...action.payload.data, quantity: 0}))
                if (index !== -1) {
                    let cart_ = [...state.cart];
                    cart_[index].quantity += action.payload.data.quantity
                    return {
                        ...state,
                        cart: [...cart_]
                    }
                } else {
                    return {
                        ...state,
                        cart: [...state.cart, action.payload.data]
                    }
                }
            }

        case "REMOVE_FROM_CART":
            {
                const item = state.cart[action.payload.id]
                return {
                    ...state,
                    cart: state.cart.filter(x => x !== item)
                }
            }

        case "EMPTY_CART":
            {
                let order = action.payload.data
                if (!localStorage.getItem('history')) {
                    localStorage.setItem('history', JSON.stringify([]));
                }
                let orders = JSON.parse(localStorage.getItem('history'))
                orders.push(order);
                localStorage.setItem('history', JSON.stringify(orders));
                return {
                    ...state,
                    cart: [],
                    orderMode: {}
                }
            }

        case "ADD_TASTE_PROFILE":
            {
                localStorage.setItem(action.payload.data.key, JSON.stringify(action.payload.data.value))
                return {
                    ...state,
                }
            }

        case "ADD_TO_FAVORTIES":
            {
                if (!localStorage.getItem('favorites')) {
                    localStorage.setItem('favorites', JSON.stringify([]));
                }
                let favorites = JSON.parse(localStorage.getItem('favorites'));
                if (favorites.includes(action.payload.data)) {
                    favorites = favorites.filter(x => x !== action.payload.data)
                } else {
                    favorites.push(action.payload.data)
                }
                localStorage.setItem('favorites', JSON.stringify(favorites));
                return {
                    ...state
                }
            }

        case "ADMIN":
            {
                return {
                    ...state,
                    info: action.payload.data
                }
            }

        case "ORDER_MODE":
            {
                return {
                    ...state,
                    orderMode: action.payload.data
                }
            }

        case "SET_ADMIN_MODE":
            {
                return {
                    ...state,
                    isSuper: action.payload.data
                }
            }

        case "SET_CART_EMPTY":
            {
                return {
                    ...state,
                    cart: []
                }
            }

        default:
            return state;
    }
};