import { axios, base_url } from "./imports";

export async function getCategories() {
    let response = await axios.get(base_url + "categories");
    let result = response.data.result?.filter(x => x.hasProducts && x.active) || []
    result.sort((a, b) => {
        return a.rank - b.rank;
    });
    return result;
}

export async function getRecommended() {
    let response = await axios.get(base_url + "getRecommendedProducts");
    return response.data.result;
}

export async function getUserLanguage() {
    let response = await axios.get(base_url + "getUserLanguage");
    return response.data;
}

export async function getAllWelcomeMessages () {
    let response = await axios.get(base_url + "getAllWelcomeMessages");
    return response.data;
}

export async function getLabel(id) {
    let response = await axios.get(base_url + `getLabel/${id}`);
    return response.data;
}

export async function getProductLabel(id) {
    let response = await axios.get(base_url + `getProductLabel/${id}`);
    return response.data;
}

export async function getCategoryLabel(id) {
    let response = await axios.get(base_url + `getCategoryLabel/${id}`);
    return response.data;
}


export async function getAddonsLabel(id) {
    let response = await axios.get(base_url + `getAddonsLabel/${id}`);
    return response.data;
}


export async function getVariantsLabel(id) {
    let response = await axios.get(base_url + `getVariantsLabel/${id}`);
    return response.data;
}

export async function getAllTranslations(id) {
    let response = await axios.get(base_url + `getAllTranslations/${id}`);
    return response.data;
}


export async function getOrderingEnabledStatus() {
    let response = await axios.get(base_url + "getOption/order_enabled");
    return response.data.result.dato === "1";
}

export async function setOrderingEnabledStatus(newStatus) {
    let response = await axios.post(base_url + "setOption", {
        opzione: "order_enabled",
        dato: newStatus ? 1 : 0,
    });
    return response.data;
}

export const qrCodeGenerate = async (startTable, endTable, email, handleClose) => {
    try {
      await axios.post(base_url +'generate_qr_codes', {
        startTable,
        endTable,
        email,
      });
      alert('QR codes generated and email sent successfully!');
    } catch (error) {
      alert('An error occurred while generating the QR codes.');
      console.error(error);
    }
    handleClose();
  };