const ROUTES = {
    COMMON: {
        HOME: "/",
        ABOUT: "/about",
    },
    ADMIN: {
        PRODUCTS: "/adminProducts",
        CATEGORIES: "/adminCategories",
        ADD_ONS: "/adminAddons",
        TRANSLATE_LANGUAGE_ADDONS: "/adminTranslateAddonsLanguage",
        TRANSLATE_LANGUAGE_GROUPS: "/adminTranslateGroupsLanguage",
        TRANSLATE_LANGUAGE_INGREDIENTS: "/adminTranslateIngredientsLanguage",
        TASTE_PROFILES: "/adminTasteProfiles",
        ORDERS: "/adminOrders",
        LANGUAGE_KEY: "/adminLanguageKey",
        TRANSLATE_LANGUAGE: "/adminTranslateLanguage",
        EDIT_TRANSLATE_PRODUCT_LANGUAGE: "/adminEditTranslateProductLanguage/:id",
        EDIT_TRANSLATE_CATEGORIES_LANGUAGE: "/adminEditTranslateCategoriesLanguage/:id",
        TRANSLATE_LANGUAGE_CATEGORIES: "/adminTranslateCategoriesLanguage",
        TRANSLATE_LANGUAGE_PRODUCT: "/adminTranslateProductLanguage",
        PAYMENT_METHODS: "/adminPaymentMethods",
        BUSINESS_INFO: "/adminBusinessInfo",
        VARIANTS: "/variants",
        TRANSLATE_LANGUAGE_VARIANTS: "/adminTranslateVariantsLanguage",
        LOGIN: "/login",
        ADMINS: "/manageAdmins",
        CSS_TEMPLATE: '/cssTemplate',
        GROUPS: '/variantsGroups',
        INGREDIENTS: '/ingredients',
    },
    USER: {
        CATEGORY: "/category/:name/:id",
        PRODUCT: "/product/:name/:id",
        CART: "/cart",
        INFO: "/user/:type",
        ADDRESS: "/address",
        REVIEW: "/review",
        CHECKOUT: "/checkout/:mode/:id",
        PAYMENT: "/payment"
    }
}

export default ROUTES;
