import { Avatar, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getVariantsLabel } from "../../apis/common";

export default function Variant({ data, variant, setVariant, price, setPrice, quantity }) {
    const [variantsLabels, setVariantsLabels] = React.useState([]);

    const getVariantLabel = (languageKey) => {
        const languageItem = variantsLabels.find((obj) => obj.base_text === languageKey);
        return languageItem ? languageItem.text : languageKey;
    };


    const fetchVariantsLabel = async () => {
        try {
            const storedLanguage = localStorage.getItem('selectedLanguage');
            if (storedLanguage) {
                const response = await getVariantsLabel(storedLanguage);
                if (response.code === 200) {
                    setVariantsLabels(response.result);
                }
            }
        } catch (error) {
            console.log('Error fetching language data:', error);
        }
    };

    const formatPriceDisplay = (price) => {
        if (price === 0) return "Gratis";
        if (price < 0) return `−€${Math.abs(price)}`;
        return `+€${price}`;
    };

    useEffect(() => {
        data?.forEach(element => {
            element["selected"] = false;
        });

        data[0].selected = true;
        fetchVariantsLabel()
    }, [data])



    return (
        <div style={{ display: "flex", flexDirection: "row", marginTop: 10, flexWrap: "wrap" }}>
            {
                data?.map((variant_, key) => {
                    return (
                        <div style={{ width: 60, display: "flex", alignItems: "center", flexDirection: "column" }} onClick={() => {
                            if (price) {
                                for (let x = 0; x < variant?.length; x++) {
                                    for (let y = 0; y < data?.length; y++) {
                                        if (data[y]?.variant_id === variant[x]?.variant_id) {
                                            variant[x]["selected"] = false;
                                        }
                                    }
                                }

                                let index = variant?.findIndex(x => x.variant_id === variant_.variant_id)
                                variant[index]["selected"] = !variant[index]["selected"];
                                price = price + (variant[index]["selected"] ? 1 : -1) * variant_.price * quantity;

                                //setPrice(price);                                

                                setVariant([...variant]);

                            }
                        }}>
                            <Avatar alt={variant_?.title} src={variant_?.image} variant="rounded" sx={{ width: 40, height: 40, borderImageOutset: 5, border: variant_.selected ? '5px solid #1565C0' : 'none' }} />
                            <Typography variant="body2" sx={{ textAlign: "center", color: variant === variant_ ? "#1565C0" : "text.secondary" }}>{getVariantLabel(variant_?.title)}</Typography>
                            {variant_?.price !== undefined && variant_?.price !== null && <Typography variant="body2" sx={{ textAlign: "center", color: variant_ === variant ? "#1565C0" : "text.secondary" }}>
                                {formatPriceDisplay(variant_?.price)}
                            </Typography>}
                        </div>
                    );
                })

            }
        </div>
    );
}
