import { Avatar, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { getAddonsLabel } from "../../apis/common";

export default function Addon({ data, addons, setAddons, price, setPrice, quantity, isIngredient = false }) {
    //console.log(data);
    const [addonsLabels, setAddonsLabels] = React.useState([]);

    const getAddonLabel = (languageKey) => {
        const languageItem = addonsLabels.find((obj) => obj.base_text === languageKey);
        //console.log("addons language key:", languageKey);
        //console.log("addons labels:", addonsLabels);
        return languageItem ? languageItem.text : languageKey;
    };

    const formatPriceDisplay = (price) => {
        if (price === 0) return "Gratis";
        if (price < 0) return `−€${Math.abs(price)}`;
        return `+€${price}`;
    };
    
    const fetchAddonsLabel = async () => {
        try {
            const storedLanguage = localStorage.getItem('selectedLanguage');
            if (storedLanguage) {
                const response = await getAddonsLabel(storedLanguage);
                if (response.code === 200) {
                    setAddonsLabels(response.result);
                }
            }
        } catch (error) {
            console.log('Error fetching language data:', error);
        }
    };

    useEffect(() => {
        data?.forEach(element => {
            element["selected"] = false;
        });
        fetchAddonsLabel()
    }, [])

    return (
        <div style={{ display: "flex", flexDirection: "row", marginTop: 10, flexWrap: "wrap" }}>
            {
                data?.map((addon, key) => {
                    return (
                        <div style={{ width: 60, display: "flex", alignItems: "center", flexDirection: "column" }} onClick={() => {
                            if (price) {
                                addons[key]["selected"] = addons[key]["selected"] ? false : true;
                                price = price + (addons[key]["selected"] ? 1 : -1) * addon.price * quantity;
                                // setPrice(price);
                                setAddons([...addons])
                            }
                            if (isIngredient) {
                                //console.log(addons);
                                addons[key]["selected"] = addons[key]["selected"] ? false : true;
                                setAddons([...addons])
                            }
                        }}>
                            <Avatar alt={addon.title} src={addon?.image} variant="rounded" sx={{ width: 40, height: 40, borderImageOutset: 5, border: addon.selected ? '5px solid #1565C0' : 'none' }} />
                            <Typography variant="body2" sx={{ textAlign: "center", color: addon.selected ? "#1565C0" : "text.secondary" }}>{getAddonLabel(addon.title)}</Typography>
                            {addon.price !== undefined && addon.price !== null && <Typography variant="body2" sx={{ textAlign: "center", color: addon.selected ? "#1565C0" : "text.secondary" }}>
                                {formatPriceDisplay(addon.price)}
                            </Typography>}
                        </div>
                    );
                })
            }
        </div>
    );
}
