import React, { useState } from 'react';
import axios from 'axios';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, FormHelperText } from '@mui/material';
import { qrCodeGenerate } from "../../../apis/common"; 
import CircularProgress from '@mui/material/CircularProgress';

const QRCodeForm = ({ open, handleClose }) => {
  const [startTable, setStartTable] = useState(1);
  const [endTable, setEndTable] = useState(10);
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [loading, setLoading] = useState(false); // Add a state for the loader

  const validate = () => {
    if (startTable >= endTable) {
      setError(true);
      setHelperText('Start table must be less than end table');
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validate()) return;

    try {
      setLoading(true); 
      await qrCodeGenerate(startTable, endTable, email);
      alert('QR codes generated and email sent successfully!'); 
    } catch (error) {

    }
      finally {
        setLoading(false); 
        handleClose(); 
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>QR CODE TAVOLI</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="DA"
          type="number"
          fullWidth
          variant="standard"
          value={startTable}
          onChange={(e) => setStartTable(parseInt(e.target.value))}
          error={error}
          helperText={helperText}
        />
        <TextField
          margin="dense"
          label="A"
          type="number"
          fullWidth
          variant="standard"
          value={endTable}
          onChange={(e) => setEndTable(parseInt(e.target.value))}
        />
        <TextField
          margin="dense"
          label="Email a cui inviare"
          type="email"
          fullWidth
          variant="standard"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </DialogContent>      <DialogActions>
        <Button onClick={handleClose}>ANNULLA</Button>
        <Button onClick={handleSubmit} disabled={loading}>
          {loading ? <CircularProgress size={20} /> : 'GENERA'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QRCodeForm;
