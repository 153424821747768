import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import {getLabel} from "../../../../apis/common";

export default function AddressForm({ state, setState }) {
    const [labels, setLabels] = React.useState([]);
    const getTranslatedLabel = (languageKey) => {
        const languageItem = labels.find((obj) => obj.language_key === languageKey);
        return languageItem ? languageItem.translated_language : '';
    };
    React.useEffect(() => {
        const fetchLabel = async () => {
            try {
                const storedLanguage = localStorage.getItem('selectedLanguage');
                const response = await getLabel(storedLanguage || '');
                if (response.code === 200) {
                    setLabels(response.result);
                }
            } catch (error) {
                console.log('Error fetching language data:', error);
            }
        };

        fetchLabel()

    }, [])
    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                {getTranslatedLabel('shipping_address')}
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="firstName"
                        name="firstName"
                        label={getTranslatedLabel('first_name')}
                        fullWidth
                        autoComplete="given-name"
                        variant="standard"
                        onChange={(e) => { setState({ ...state, "first": e.target.value }) }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="lastName"
                        name="lastName"
                        label={getTranslatedLabel('last_name')}
                        fullWidth
                        autoComplete="family-name"
                        variant="standard"
                        onChange={(e) => { setState({ ...state, "last": e.target.value }) }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        id="address1"
                        name="address1"
                        label={getTranslatedLabel('address_line_1')}
                        fullWidth
                        autoComplete="shipping address-line1"
                        variant="standard"
                        onChange={(e) => { setState({ ...state, "address_1": e.target.value }) }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="address2"
                        name="address2"
                        label={getTranslatedLabel('address_line_2')}
                        fullWidth
                        autoComplete="shipping address-line2"
                        variant="standard"
                        onChange={(e) => { setState({ ...state, "address_2": e.target.value }) }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        id="phone"
                        name="phone"
                        label={getTranslatedLabel('phone_number')}
                        fullWidth
                        autoComplete="phone number"
                        variant="standard"
                        onChange={(e) => { setState({ ...state, "phone": e.target.value }) }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="city"
                        name="city"
                        label={getTranslatedLabel('city')}
                        fullWidth
                        autoComplete="shipping address-level2"
                        variant="standard"
                        onChange={(e) => { setState({ ...state, "city": e.target.value }) }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="state"
                        name="state"
                        label={getTranslatedLabel('state_province_region')}
                        fullWidth
                        variant="standard"
                        onChange={(e) => { setState({ ...state, "state": e.target.value }) }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="zip"
                        name="zip"
                        label={getTranslatedLabel('zip_postal_code')}
                        fullWidth
                        autoComplete="shipping postal-code"
                        variant="standard"
                        onChange={(e) => { setState({ ...state, "zip": e.target.value }) }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="country"
                        name="country"
                        label={getTranslatedLabel('country')}
                        fullWidth
                        autoComplete="shipping country"
                        variant="standard"
                        onChange={(e) => { setState({ ...state, "country": e.target.value }) }}
                    />
                </Grid>
                {/* <Grid item xs={12}>
                    <FormControlLabel
                        control={<Checkbox color="secondary" name="saveAddress" value="yes" />}
                        label="Use this address for payment details"
                    />
                </Grid> */}
            </Grid>
        </React.Fragment>
    );
}
