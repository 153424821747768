import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/captioned.css';
import './Carousal.scss'
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const AutoplaySlider = withAutoplay(AwesomeSlider);

export default function Carousal({ recommended, labels, productlabels }) {
    const navigate = useNavigate();
    const getTranslatedLabel = (languageKey) => {
        const languageItem = labels.find((obj) => obj.language_key === languageKey);
        return languageItem ? languageItem.translated_language : '';
    };

    const getTranslatedProductName = (object, product_id, type, default_text) => {
        //console.log('getTranslatedProductName called with:', { object, product_id, type, default_text });
    
        const languageItem = productlabels?.find((obj) => obj.object == object && obj.objectId == product_id && obj.type == type);
        //console.log('getTranslatedProductName labels:', productlabels);
        //console.log('getTranslatedProductName languageItem:', languageItem);
        const result = languageItem ? languageItem.text : default_text;
        //console.log('getTranslatedProductName result:', result);
        return result;
    
      };    

    return (
        <>
            <Typography variant={window.innerWidth <= 500 ? 'h4' : 'h3'} sx={{ fontWeight: "bold", fontFamily: 'Clearface', textAlign: "center" }}>{getTranslatedLabel('recommended_products')}</Typography>
            <AutoplaySlider
                animation="cubeAnimation"
                play={true}
                style={{ height: window.innerWidth <= 500 ? 150 : 500, marginTop: 50, marginBottom: 40 }}
                cancelOnInteraction={false}
                interval={4000}
            >
                {
                    recommended?.map((product) => {
                        //console.log("featured:", product);
                        return (
                            <div className="carousel__slide-item carousel__slide-item-img-link" onClick={() => { navigate(`product/${product?.Category?.title}/${product?.Category?.category_id}`) }} >
                                <div style={{ width: "100%" }} className='container' >
                                    <img src={product.image} />
                                    <div class="textInsideImage" style={{fontSize: window.innerWidth <= 500 ? 12 : 18}}>{getTranslatedProductName("product", product?.product_id, "title", product?.title)}</div>
                                </div>
                            </div>
                        );
                    })
                }
            </AutoplaySlider>
        </>
    );
}
