import React, { useEffect } from "react";
import { Box } from "@mui/system";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Typography } from "@mui/material";

function TransactionSuccessfull({setPaymentSuccess, navigation}) {
    useEffect(()=>{
        setTimeout(()=>{
            setPaymentSuccess(null);
        }, 2000)
    }, [])
    return (
        <Box style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', justifyContent: 'center', marginTop: 120 }}>
            <CheckCircleOutlineIcon style={{ color: 'green', fontSize: 250, alignSelf: 'center' }} />
            <Typography style={{ color: 'green', fontSize: 20, textAlign: "center", marginTop: 20 }}>{"Transaction Successfull"}</Typography>
            <Typography style={{ fontSize: 15, textAlign: "center", marginTop: 10 }}>{"Redirecting back to order confirmation..."}</Typography>
        </Box>
    );
}

export default TransactionSuccessfull;