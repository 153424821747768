import React, { useEffect, useState } from 'react';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Card, CardHeader, CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Select } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './styles.scss'
import dummy from '../../../assets/images/dummy.jpeg';
import { addVariant, getAdminProducts, getGroups, updateVariant } from '../../../apis/admin';

function VariantEditCard({ setData, data, closeBackdrop, setChange, change, setText, setOpenFailed, setOpenSucess }) {
    const [title, setTitle] = useState("");
    const [price, setPrice] = useState(data?.price || 0);
    const [product, setProduct] = useState(data?.product_id || 1);
    const [group, setGroup] = useState(data?.group_id || 0);
    const [products, setProducts] = useState([])
    const [groups, setGroups] = useState([])
    const [file, setFile] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function fetchData() {
            setProducts(await getAdminProducts())
            setGroups(await getGroups())
        }
        fetchData();
    }, [])

    useEffect(() => {
        console.log(data);
        setPrice(data?.price || "");
        setTitle(data?.title || "");
        setGroup(data?.group_id || "");
        setProduct(data?.product_id || "");
        setFile();
    }, [data])

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data_ = new FormData(event.currentTarget);
        const updatedAddon = {
            id: data?.variant_id,
            title: data_.get("title"),
            price: data_.get("price"),
            product: data_.get("product"),
            group: data_.get("group"),
            file: file
        };

        let form = new FormData();
        form.append('title', updatedAddon.title);
        form.append('price', updatedAddon.price);
        form.append('product_id', updatedAddon.product);
        form.append('group_id', updatedAddon.group);
        form.append('file', file)

        if (data) {
            setLoading(true);
            const response = await updateVariant(updatedAddon.id, form);
            console.log(response)
            setLoading(false);
            if (response.code === 200) {
                setText("Variant successfully updated!");
                setOpenSucess(true);
            } else {
                setText("There was an error while updating the Variant!");
                setOpenFailed(true);
            }
        } else {
            setLoading(true);
            const response = await addVariant(form);
            console.log(response)
            setLoading(false);
            if (response.code === 200) {
                setText("Variant successfully added!");
                setOpenSucess(true);
            } else {
                setText("There was an error while adding the Variant!");
                setOpenFailed(true);
            }
        }

        setChange(!change);
        closeBackdrop();
        setData({});
    };

    const changeProduct = (event) => {
        setProduct(event.target.value);
    }

    const changeGroup = (event) => {
        setGroup(event.target.value);
    }

    return (
        <Card sx={{ padding: 2, width: "46%" }}>
            <CardHeader
                action={
                    <IconButton aria-label="settings"
                        onClick={closeBackdrop}
                    >
                        <CloseIcon sx={{ alignSelf: "flex-end" }} />
                    </IconButton>
                }
                title={data ? "Edit Variant" : "Add new Variant"}
                sx={{ color: "black" }}
            />

            <Container component="main">
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <div style={{ display: "flex", flexDirection: 'row', justifyContent: "space-between", alignItems: "center" }}>
                        <div>
                            <TextField
                                value={title}
                                margin="normal"
                                required
                                fullWidth
                                id="title"
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                }}
                                label="Title"
                                name="title"
                                autoComplete="title"
                                autoFocus
                            />
                            <TextField
                                value={price}
                                margin="normal"
                                required
                                fullWidth
                                id="price"
                                label="Price"
                                name="price"
                                autoComplete="price"
                                autoFocus
                                onChange={(e) => {
                                    setPrice(e.target.value);
                                }}
                            />
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Variant Groups</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="group"
                                    name='group'
                                    value={group}
                                    label="Variant Groups"
                                    onChange={changeGroup}
                                >
                                    {
                                        groups?.map((group, key) => {
                                            return (
                                                <MenuItem value={group.id}>{group.title}</MenuItem>
                                            );
                                        })
                                    }
                                </Select>
                            </FormControl>
                            <FormControl fullWidth sx={{ mt: 2 }}>
                                <InputLabel id="demo-simple-select-label">Product</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="product"
                                    name='product'
                                    value={product}
                                    label="Product"
                                    onChange={changeProduct}
                                >
                                    {
                                        products?.map((product, key) => {
                                            return (
                                                <MenuItem value={product.product_id}>{product.title}</MenuItem>
                                            );
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        <Box style={{ display: "flex", flexDirection: "column", width: 230 }}>
                            <div className="carousel__slide-item carousel__slide-item-img-link" onClick={() => {
                                document.querySelector('input[id="image_input"]').click();
                            }}>
                                <input
                                    type="file"
                                    hidden
                                    id="image_input"
                                    onChange={(e) => { setFile(e.target.files[0]) }}
                                    style={{ display: "none" }}
                                />
                                <img
                                    component="img"
                                    style={{ height: 230, width: 230, objectFit: "cover", borderRadius: 10 }}
                                    alt="Logo"
                                    src={file ? URL.createObjectURL(file) : data?.image ? data?.image : dummy}
                                />
                            </div>
                        </Box>
                    </div>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {data ? "Update Info" : "Add Variant"}
                        {
                            loading && <CircularProgress size={15} color="inherit" sx={{ ml: 1 }} />
                        }
                    </Button>
                </Box>
            </Container>
        </Card >
    );
}

export default VariantEditCard;
