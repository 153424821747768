import { Box, Card, CardHeader, Container, IconButton, TextField, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from "react";
import AddBoxRounded from '@mui/icons-material/AddBoxRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import { getProductsByGroup, pairProductsToGroup } from "../../../apis/admin";

function ProductRow({ product, handleChange, group_id }) {
    const checkActive = () => {
        const variant = product.Variants.find(x => x.group_id === group_id)
        return variant?.isActive;
    }
    return (
        <Box sx={{ display: "flex", flexDirection: "row", gap: 10, margin: 2, justifyContent: "space-between", alignItems: "center" }}>
            <Typography>{product.title}</Typography>
            <IconButton onClick={() => handleChange(product.product_id)}>
                {
                    checkActive() ?
                        <DeleteIcon htmlColor="red" /> :
                        <AddBoxRounded color="primary" fontSize="large" />
                }
            </IconButton>
        </Box >
    )
}

function PairProducts({ data, closeBackdrop }) {
    const [products, setProducts] = useState([]);
    const [copyProducts, setCopyProducts] = useState([]);
    const [change, setChange] = useState(false);
    const [title, setTitle] = useState("");

    useEffect(() => {
        async function fetchData() {
            if (data?.id) {
                const response = await getProductsByGroup(data?.id);
                setProducts(response);
                setCopyProducts(response);
            }
        }
        fetchData();
    }, [data, change])

    useEffect(() => {
        if (title.length > 3) {
            setProducts(copyProducts.filter(x => x?.title?.toLowerCase()?.includes(title.toLowerCase())))
        } else {
            setProducts(copyProducts);
        }
    }, [title])

    const handleChange = async (product_id) => {
        const product = products.find(x => x.product_id === product_id)
        const variant = product.Variants.find(x => x.group_id === data?.id)
        let status = true;
        if (variant?.isActive) {
            status = false;
        }

        await pairProductsToGroup(product_id, data?.id, status);
        setChange(!change)
    }

    return (
        <Card sx={{ padding: 2, width: "70%", height: "70%", maxHeight: "90%", overflowY: "scroll" }}>
            <CardHeader
                action={
                    <IconButton aria-label="settings"
                        onClick={() => { closeBackdrop() }}
                    >
                        <CloseIcon sx={{ alignSelf: "flex-end" }} />
                    </IconButton>
                }
                title={data?.title}
                sx={{ color: "black" }}
            />

            <Container component="main">
                <TextField
                    value={title}
                    margin="normal"
                    required
                    fullWidth
                    id="title"
                    onChange={(e) => {
                        setTitle(e.target.value);
                    }}
                    label="Search product"
                    name="title"
                    autoComplete="title"
                    autoFocus
                />
                <Box component="form" sx={{ mt: 1 }}>
                    {
                        products.map((product, index) => (
                            <ProductRow product={product} key={index} handleChange={handleChange} group_id={data?.id} />
                        ))
                    }
                </Box>
            </Container>
        </Card>
    );
}

export default PairProducts;