import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress';
import { addAdmin } from '../../../apis/admin';
import { useState } from "react";
import { Card, CardHeader, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Visibility, VisibilityOff } from "@mui/icons-material";

export default function AddAdmin({ setOpen, change, setChange, openSuccess, openFailed, setText }) {
    const [loading, setLoading] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [error, setError] = useState(false);
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
    const [confirm, setConfirm] = useState("")

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (password !== confirm) {
            setPasswordError(true);
            setText("Passwords do not match!")
            openFailed(true);
            return;
        }
        setLoading(true)
        const response = await addAdmin({ username, password });
        setLoading(false);
        if (!response.result) {
            setError(true)
            setText("Admin already exists with given username!")
            openFailed(true);
            return;
        }
        setChange(!change)
        setOpen(false);
        setText("Admin added successfully!")
        openSuccess(true)
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowPasswordConfirm = () => setShowPasswordConfirm((show) => !show);

    const handleMouseDownPasswordConfirm = (event) => {
        event.preventDefault();
    };

    return (
        <Card sx={{ width: 600, padding: 5 }}>
            <CardHeader
                action={
                    <IconButton aria-label="settings"
                        onClick={() => { setOpen(false); setError(false) }}
                    >
                        <CloseIcon />
                    </IconButton>
                }
                title={"Register Admin"}
            />
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                    error={error}
                    value={username}
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Username"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    onChange={(e) => {
                        setUsername(e.target.value);
                    }}
                    onFocus={() => { setError(false) }}
                />
                <FormControl sx={{ width: "100%" }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                    <OutlinedInput
                        id="password"
                        name="password"
                        error={error || passwordError}
                        value={password}
                        type={showPassword ? 'text' : 'password'}
                        required
                        autoComplete="password"
                        autoFocus
                        onChange={(e) => {
                            setPassword(e.target.value);
                        }}
                        onFocus={() => { setError(false); setPasswordError(false) }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Password"
                    />
                </FormControl>
                <FormControl sx={{ width: "100%", mt: 1 }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                    <OutlinedInput
                        id="confirm"
                        name="confirm"
                        error={error || passwordError}
                        value={confirm}
                        type={showPasswordConfirm ? 'text' : 'password'}
                        required
                        autoComplete="confirm"
                        autoFocus
                        onChange={(e) => {
                            setConfirm(e.target.value);
                        }}
                        onFocus={() => { setError(false); setPasswordError(false) }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPasswordConfirm}
                                    onMouseDown={handleMouseDownPasswordConfirm}
                                    edge="end"
                                >
                                    {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Confirm Password"
                    />
                </FormControl>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Register
                    {
                        loading && <CircularProgress size={15} color="inherit" sx={{ ml: 1 }} />
                    }
                </Button>
            </Box>
        </Card>
    );
}