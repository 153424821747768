import React, { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {
    Button, Card,
    CardHeader,
    CircularProgress,
} from '@mui/material';
import { getAdminProducts } from '../../../apis';
import SuccessToast from '../../../components/common/SuccessToast';
import FailedToast from '../../../components/common/FailedToast';
import {
    EditProductLanguage,
    updateProductLanguage
} from '../../../apis/admin';
import { Link, useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import store from '../../../store';
import ROUTES from '../../../routes';

function EnhancedTableToolbar() {
    return (
        <Toolbar>
            <Typography
                sx={{ flex: '1 1 100%', fontFamily: 'Clearface' }}
                variant="h6"
                id="tableTitle"
                component="div"
                fontWeight={"bold"}
            >
                Edit Products Language
            </Typography>
        </Toolbar>
    );
}

export default function EditTranslateProductLanguage() {
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [arrayData, setArrayData] = useState([]);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openFailed, setOpenFailed] = useState(false);
    const [text, setText] = useState("");

    const [editData, setEditData] = useState({
        id: "",
        name: "",
        translations: [],
    });

    const [languageData, setLanguageData] = useState();

    let updateTitle;
    let updateDes;
    let updateTitleId;
    let updateDesId;

    const url = window.location.href;
    const parts = url.split('/');
    const id = parts[parts.length - 1];

    const navigate = useNavigate();
    useEffect(() => {
        const state = store.getState();
        if (state.mode != "admin") {
            navigate(ROUTES.ADMIN.LOGIN);
        }
        async function fetchData() {
            setLoading(true);
            setProducts(await getAdminProducts());
            setLoading(false);
        }

        fetchData();

        getEditData();
    }, []);


    const getDescriptionTranslation = (productId) => {
        const productTranslation = arrayData.find(data => data.objectId === productId && data.type === "description");
        if (productTranslation && productTranslation.text) {
            return productTranslation.text;
        }

        return "";
    };

    const getTitleTranslation = (productId) => {
        const productTranslation = arrayData.find(data => data.objectId === productId && data.type === "title");
        if (productTranslation && productTranslation.text) {
            return productTranslation.text;
        }

        return "";
    };


    const handleTranslatedLanguageChange = (productId, value, type) => {
        const productTranslation = arrayData.find(data => data.objectId === productId && data.type === type);
        if (productTranslation) {
            if (type === "title") {
                updateTitle = value;
                updateTitleId = productTranslation.id;
            }
            if (type === "description") {
                updateDes = value;
                updateDesId = productTranslation.id;
            }
        }

        // console.log(updateTitleId)
        // console.log(updateDes)
    };


    const handleSubmit = async (event, productId) => {
        event.preventDefault();

        try {

            if (updateTitle || updateDes) {
                const updatedTranslation1 = {
                    id: updateTitleId,
                    text: updateTitle,
                };

                const updatedTranslation2 = {
                    id: updateDesId,
                    text: updateDes,
                };

                const updatedEditData = {
                    id: '',
                    name: '',
                    translations: [updatedTranslation1, updatedTranslation2],
                };

                const response = await updateProductLanguage({
                    ...updatedEditData,
                });

                if (response.code === 200) {
                    setText("Language successfully updated!");
                    setOpenSuccess(true);
                } else {
                    setText("There was an error while updating the Language!");
                    setOpenFailed(true);
                }
            }

            // else {
            //     const updatedTranslation = {
            //         id: updateTitleId,
            //         text: updateTitle,
            //     };
            //     const updatedEditData = {
            //         id: '',
            //         name: '',
            //         translations: [updatedTranslation],
            //     };
            //     const response = await updateProductLanguage({
            //         ...updatedEditData,
            //     });
            //     if (response.code === 200) {
            //         setText("Language successfully updated!");
            //         setOpenSuccess(true);
            //     } else {
            //         setText("There was an error while updating the Language!");
            //         setOpenFailed(true);
            //     }
            // }

            // Handle response


        } catch (error) {
            console.error(error);
        }
    };


    const getEditData = async () => {
        const response = await EditProductLanguage(id);

        if (response.code === 200) {
            const resData = response.result;

            if (resData.length > 0) {
                const prepareArrayData = () => {
                    const newArrayData = resData.map(obj => {
                        const { id, objectId, base_text, text, type } = obj;
                        return { id, objectId, base_text, text, type };
                    });
                    setArrayData(newArrayData);
                    setLanguageData(prevState => ({
                        ...prevState,
                        translations: newArrayData,
                    }));
                };

                prepareArrayData();
            }
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccess(false);
        setOpenFailed(false);
    }

    return (
        <Box sx={{ width: '90%', marginTop: 4, display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div style={{ width: '100%', display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <EnhancedTableToolbar />
                <Button color="primary">
                    <Link to="/adminTranslateProductLanguage" style={{ textDecoration: 'none' }}>
                        Back
                    </Link>
                </Button>
            </div>

            {loading ? (
                <CircularProgress color="inherit" sx={{ mt: 2 }} />
            ) : (
                <div>
                    {products?.length === 0 ? (
                        <Typography variant='h5' sx={{
                            textAlign: "center",
                            marginTop: 10,
                            color: "text.secondary",
                            marginBottom: 50
                        }}>
                            No products available. Add a product now!
                        </Typography>
                    ) : (
                        products?.map((row) => (
                            <Card key={row.id} sx={{ padding: 2, width: "100%", my: 2 }}>
                                <CardHeader title={"#" + row.product_id + " - " + row.title} />
                                <Container component="main">
                                    <Box component="form" onSubmit={(event) => handleSubmit(event, row.product_id)}
                                        sx={{ mt: 1 }}>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: 'row',
                                            justifyContent: "space-between"
                                        }}>
                                            <div>
                                                <TextField
                                                    key={row.id}
                                                    defaultValue={getTitleTranslation(row.product_id)}
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    id={`title_${row.product_id}`}
                                                    onChange={(e) => handleTranslatedLanguageChange(row.product_id, e.target.value, 'title')}
                                                    label="Product Name"
                                                    name={`title_${row.product_id}`}
                                                    autoComplete={`title_${row.product_id}`}
                                                />
                                                <TextField
                                                    defaultValue={row.description}
                                                    margin="normal"
                                                    fullWidth
                                                    multiline
                                                    id="others"
                                                    label="Original Description"
                                                    name="others"
                                                    rows={4}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                />
                                            </div>
                                            <Box style={{ display: "flex", flexDirection: "column", width: 230 }}>
                                                <div>
                                                    <img
                                                        component="img"
                                                        style={{
                                                            height: 230,
                                                            width: 230,
                                                            objectFit: "cover",
                                                            borderRadius: 10
                                                        }}
                                                        alt="Logo"
                                                        src={row.image}
                                                    />
                                                </div>
                                            </Box>
                                        </div>

                                        <TextField
                                            defaultValue={getDescriptionTranslation(row.product_id)}
                                            margin="normal"
                                            fullWidth
                                            multiline
                                            id={`description_${row.product_id}`}
                                            label="Description Translation"
                                            name={`description_${row.product_id}`}
                                            autoComplete={`description_${row.product_id}`}
                                            rows={4}
                                            onChange={(e) => handleTranslatedLanguageChange(row.product_id, e.target.value, 'description')}
                                        />
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            sx={{ mt: 3, mb: 2 }}
                                        >
                                            {"update translation"}
                                            {loading && <CircularProgress size={15} color="inherit" sx={{ ml: 1 }} />}
                                        </Button>
                                    </Box>
                                </Container>
                            </Card>
                        ))
                    )}
                </div>
            )}

            <SuccessToast open={openSuccess} handleClose={handleClose} text={text} />
            <FailedToast open={openFailed} handleClose={handleClose} text={text} />
        </Box>
    );
}
