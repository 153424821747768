import React, { useEffect, useState } from 'react';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Card, CardHeader, CircularProgress, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './styles.scss'
import { addAddon, updateAddon } from '../../../apis';
import dummy from '../../../assets/images/dummy.jpeg';
import { addIngredient, updateIngredient } from '../../../apis/admin';

function IngredientEditCard({ setData, data, closeBackdrop, setChange, change, setText, setOpenFailed, setOpenSucess }) {
    const [title, setTitle] = useState("");
    const [file, setFile] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setTitle(data?.title || "");
        setFile();
    }, [data])

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data_ = new FormData(event.currentTarget);
        const updatedAddon = {
            id: data?.id,
            title: title,
            file: file
        };

        let form = new FormData();
        form.append('title', updatedAddon.title);
        form.append('file', file)

        if (data) {
            setLoading(true);
            const response = await updateIngredient(updatedAddon.id, form);
            setLoading(false);
            if (response.code === 200) {
                setText("Ingredient successfully updated!");
                setOpenSucess(true);
            } else {
                setText("There was an error while updating the Ingredient!");
                setOpenFailed(true);
            }
        } else {
            setLoading(true);
            const response = await addIngredient(form);
            setLoading(false);
            if (response.code === 200) {
                setText("Ingredient successfully added!");
                setOpenSucess(true);
            } else {
                setText("There was an error while adding the Ingredient!");
                setOpenFailed(true);
            }
        }

        setChange(!change);
        closeBackdrop();
        setData({});
    };

    return (
        <Card sx={{ padding: 2, width: "46%" }}>
            <CardHeader
                action={
                    <IconButton aria-label="settings"
                        onClick={closeBackdrop}
                    >
                        <CloseIcon sx={{ alignSelf: "flex-end" }} />
                    </IconButton>
                }
                title={data ? "Edit Ingredient" : "Add new Ingredient"}
                sx={{ color: "black" }}
            />

            <Container component="main">
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <div style={{ display: "flex", flexDirection: 'row', justifyContent: "space-between", alignItems: "center" }}>
                        <div>
                            <TextField
                                value={title}
                                margin="normal"
                                required
                                fullWidth
                                id="title"
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                }}
                                label="Title"
                                name="title"
                                autoComplete="title"
                                autoFocus
                            />
                        </div>
                        <Box style={{ display: "flex", flexDirection: "column", width: 230 }}>
                            <div className="carousel__slide-item carousel__slide-item-img-link" onClick={() => {
                                document.querySelector('input[id="image_input"]').click();
                            }}>
                                <input
                                    type="file"
                                    hidden
                                    id="image_input"
                                    onChange={(e) => { setFile(e.target.files[0]) }}
                                    style={{ display: "none" }}
                                />
                                <img
                                    component="img"
                                    style={{ height: 230, width: 230, objectFit: "cover", borderRadius: 10 }}
                                    alt="Logo"
                                    src={file ? URL.createObjectURL(file) : data?.image ? data?.image : dummy}
                                />
                            </div>
                        </Box>
                    </div>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {data ? "Update Info" : "Add Ingredient"}
                        {
                            loading && <CircularProgress size={15} color="inherit" sx={{ ml: 1 }} />
                        }
                    </Button>
                </Box>
            </Container>
        </Card >
    );
}

export default IngredientEditCard;
