import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import { getTasteProfiles } from '../../../apis/admin';
import { useDispatch } from 'react-redux';
import { addTasteProfile } from '../../../store/actions/userActions';
import { Typography } from '@mui/material';

export default function TasteProfile({ labels, staticData }) {
  const [allergen, setAllergen] = React.useState(JSON.parse(localStorage.getItem('allergens')) || []);
  const [nutrition, setNutrition] = React.useState(JSON.parse(localStorage.getItem('nutritions')) || []);
  const [tasteProfiles, setTasteProfiles] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  console.log('TasteProfile called with:', { labels, staticData });

  const getTranslatedLabel = (object, product_id, type, default_text) => {
    console.log('getTranslatedLabel called with:', { object, product_id, type, default_text, labels });

    if (!labels) {
      return default_text;
    }

    if (!object || !product_id || !type) {
      return default_text;
    }

    const languageItem = labels.find((obj) =>  obj.object === object && obj.objectId === product_id && obj.type === type);
    const result = languageItem ? languageItem.text : default_text;
    console.log('getTranslatedLabel result:', result);
    return result;
    
  };

  const getStaticLabel = (languageKey, defaultText) => {
    console.log('getStaticLabel called with:', { languageKey, staticData });
    const languageStaticItem = staticData.find((obj) => obj.language_key === languageKey);
    return languageStaticItem ? languageStaticItem.translated_language : defaultText;
  };

  React.useEffect(() => {
    async function fetchData() {
      setLoading(true)
      setTasteProfiles(await getTasteProfiles())
      setLoading(false);
    }
    fetchData();
    console.log(localStorage.getItem('allergens'))
  }, [])

  const handleToggleAllergen = (value) => () => {
    const currentIndex = allergen.indexOf(value);
    const newChecked = [...allergen];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setAllergen(newChecked);
    dispatch(addTasteProfile({ key: "allergens", value: newChecked }))
    console.log(newChecked)
  };

  const handleToggleNutrition = (value) => () => {
    const currentIndex = nutrition.indexOf(value);
    const newChecked = [...nutrition];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setNutrition(newChecked);
    dispatch(addTasteProfile({ key: "nutritions", value: newChecked }))
    console.log(newChecked)
  };

  return (
    <>
      <Typography variant='h5'>{getStaticLabel('nutrition', 'Nutrition')}</Typography>
      <List sx={{ width: '100%', display: "flex", flexDirection: "row", flexWrap: "wrap", padding: 5 }}>
        {tasteProfiles?.filter(x => x.type === "Nutrition").map((value, key) => {
           console.log("NUTRITION: ", value);
          const labelId = `checkbox-list-secondary-label-${value.title}`;
          return (
            <ListItem
              key={key}
              secondaryAction={
                <Checkbox
                  edge="end"
                  onChange={handleToggleNutrition(value.title)}
                  checked={nutrition.indexOf(value.title) !== -1}
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              }
              disablePadding
              sx={{ width: 300 }}
            >
              <ListItemButton>
                <ListItemAvatar>
                  <Avatar
                    alt={`Taste Profile ${key}`}
                    src={value.image}
                  />
                </ListItemAvatar>
                <ListItemText id={labelId} primary={getTranslatedLabel("Nutrition", value.tasteProfile_id, "title", value.title)} value={value.id} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>

      <Typography variant='h5'>{getStaticLabel('allergens', 'Allergens')}</Typography>
      <List sx={{ width: '100%', padding: 5, display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        {tasteProfiles?.filter(x => x.type === "Allergen").map((value, key) => {
          const labelId = `checkbox-list-secondary-label-${value.title}`;
          return (
            <ListItem
              key={key}
              secondaryAction={
                <Checkbox
                  edge="end"
                  onChange={handleToggleAllergen(value.title)}
                  checked={allergen.indexOf(value.title) !== -1}
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              }
              disablePadding
              sx={{ width: 300 }}
            >
              <ListItemButton>
                <ListItemAvatar>
                  <Avatar
                    alt={`Taste Profile ${key}`}
                    src={value.image}
                  />
                </ListItemAvatar>
                <ListItemText id={labelId} primary={getTranslatedLabel("Allergen", value.tasteProfile_id, "title", value.title)} value={value.id} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </> 
  );
}
