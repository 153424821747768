import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions, CardHeader, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function JoinUs({ text, link, setOpen, image }) {
    return (
        <Card sx={{ width: 700 }}>
            <CardHeader
                action={
                    <IconButton aria-label="settings"
                        onClick={() => { setOpen(false) }}
                    >
                        <CloseIcon sx={{ alignSelf: "flex-end" }} />
                    </IconButton>
                }
                title={"Work with us"}
                sx={{ color: "black", fontWeight: "bold" }}
            />
            <CardActionArea>
                <CardMedia
                    component="img"
                    height="140"
                    image={image}
                    alt="join us"
                />
                <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                        Hello Passionate People!
                    </Typography>
                    <Typography variant="body2" color="text.secondary">{text}</Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <a href={`${link}`} target="_blank">
                    <Button size="medium" color="primary">
                        View vacancies
                    </Button>
                </a>
            </CardActions>
        </Card>
    );
}