import { createBrowserRouter, Outlet } from "react-router-dom";
import ROUTES from '../routes';
import { About, Home } from "../pages/common";
import { Addons, BusinessInfo, Categories, PaymentMethods, Products, TasteProfiles, Variants } from "../pages/admin";
import Order from "../pages/admin/Orders";
import { Category, Checkout, Product, User } from "../pages/user";
import { Login } from "../components/Admin";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import ErrorPage from "../pages/common/ErrorPage";
import Admins from "../pages/admin/Admins";
import LanguageKey from "../pages/admin/LanguageKey";
import TranslateLanguage from "../pages/admin/TranslateLanguage";
import TranslateProductLanguage from "../pages/admin/TranslateProducts";
import EditTranslateProductLanguage from "../pages/admin/EditTranslateProduct";
import TranslateCategoriesLanguage from "../pages/admin/TranslateCategories";
import TranslateAddonsLanguage from "../pages/admin/TranslateAddons";
import TranslateVariantsLanguage from "../pages/admin/TranslateVariants";
import CssTemplate from "../pages/admin/CssTemplate";
import Groups from "../pages/admin/Groups";
import Ingredients from "../pages/admin/Ingredients";
import TranslateIngredients from "../pages/admin/TranslateIngredients";
import TranslateGroups from "../pages/admin/TranslateGroups";



const routes = [
    {
        element: <Outlet />,

        errorElement: <>
            <Header />
            <ErrorPage />
            <Footer />
        </>,
        children: [
            {

                path: ROUTES.COMMON.HOME,
                element: <Home />,
            },
            {
                path: ROUTES.COMMON.ABOUT,
                element: <><Header />
                    <div style={{ minHeight: `calc(100vh - 10vh)`, width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                            <About />
                        </div>
                    </div>
                    <Footer />
                </>,
            },


            {
                path: ROUTES.USER.PRODUCT,
                element: <><Header />
                    <div style={{ minHeight: `calc(100vh - 10vh)`, width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                            <Product />
                        </div>
                    </div>
                    <Footer />
                </>,
            },
            {
                path: ROUTES.USER.INFO,
                element: <><Header />
                    <div style={{ minHeight: `calc(100vh - 10vh)`, width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                            <User />
                        </div>
                    </div>
                    <Footer />
                </>,
            },
            {
                path: ROUTES.USER.CATEGORY,
                element: <><Header />
                    <div style={{ minHeight: `calc(100vh - 10vh)`, width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                            <Category />
                        </div>
                    </div>
                    <Footer />
                </>,
            },
            {
                path: ROUTES.USER.CHECKOUT,
                element: <><Header />
                    <div style={{ minHeight: `calc(100vh - 10vh)`, width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                            <Checkout />
                        </div>
                    </div>
                    <Footer />
                </>,
            },


            {
                path: ROUTES.ADMIN.LOGIN,
                element: <><Header />
                    <div style={{ minHeight: `calc(100vh - 10vh)`, width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                            <Login />
                        </div>
                    </div>
                    <Footer />
                </>,
            },
            {
                path: ROUTES.ADMIN.ADD_ONS,
                element: <><Header />
                    <div style={{ minHeight: `calc(100vh - 10vh)`, width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                            <Addons />
                        </div>
                    </div>
                    <Footer />
                </>,
            },
            {
                path: ROUTES.ADMIN.INGREDIENTS,
                element: <><Header />
                    <div style={{ minHeight: `calc(100vh - 10vh)`, width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                            <Ingredients />
                        </div>
                    </div>
                    <Footer />
                </>,
            },
            {
                path: ROUTES.ADMIN.GROUPS,
                element: <><Header />
                    <div style={{ minHeight: `calc(100vh - 10vh)`, width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                            <Groups />
                        </div>
                    </div>
                    <Footer />
                </>,
            },
            {
                path: ROUTES.ADMIN.BUSINESS_INFO,
                element: <><Header />
                    <div style={{ minHeight: `calc(100vh - 10vh)`, width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                            <BusinessInfo />
                        </div>
                    </div>
                    <Footer />
                </>,

            },
            {
                path: ROUTES.ADMIN.VARIANTS,
                element: <><Header />
                    <div style={{ minHeight: `calc(100vh - 10vh)`, width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                            <Variants />
                        </div>
                    </div>
                    <Footer />
                </>,

            },
            {
                path: ROUTES.ADMIN.CATEGORIES,
                element: <><Header />
                    <div style={{ minHeight: `calc(100vh - 10vh)`, width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                            <Categories />
                        </div>
                    </div>
                    <Footer />
                </>,
            },
            {
                path: ROUTES.ADMIN.ORDERS,
                element: <><Header />
                    <div style={{ minHeight: `calc(100vh - 10vh)`, width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                            <Order />
                        </div>
                    </div>
                    <Footer />
                </>,
            },
            {
                path: ROUTES.ADMIN.LANGUAGE_KEY,
                element: <><Header />
                    <div style={{ minHeight: `calc(100vh - 10vh)`, width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                            <LanguageKey />
                        </div>
                    </div>
                    <Footer />
                </>,
            },
            {
                path: ROUTES.ADMIN.TRANSLATE_LANGUAGE,
                element: <><Header />
                    <div style={{ minHeight: `calc(100vh - 10vh)`, width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                            <TranslateLanguage />
                        </div>
                    </div>
                    <Footer />
                </>,
            },
            {
                path: ROUTES.ADMIN.TRANSLATE_LANGUAGE_PRODUCT,
                element: <><Header />
                    <div style={{ minHeight: `calc(100vh - 10vh)`, width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                            <TranslateProductLanguage />
                        </div>
                    </div>
                    <Footer />
                </>,
            },
            {
                path: ROUTES.ADMIN.TRANSLATE_LANGUAGE_CATEGORIES,
                element: <><Header />
                    <div style={{ minHeight: `calc(100vh - 10vh)`, width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                            <TranslateCategoriesLanguage />
                        </div>
                    </div>
                    <Footer />
                </>,
            },
            {
                path: ROUTES.ADMIN.TRANSLATE_LANGUAGE_ADDONS,
                element: <><Header />
                    <div style={{ minHeight: `calc(100vh - 10vh)`, width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                            <TranslateAddonsLanguage />
                        </div>
                    </div>
                    <Footer />
                </>,
            },
            {
                path: ROUTES.ADMIN.TRANSLATE_LANGUAGE_INGREDIENTS,
                element: <><Header />
                    <div style={{ minHeight: `calc(100vh - 10vh)`, width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                            <TranslateIngredients />
                        </div>
                    </div>
                    <Footer />
                </>,
            },
            {
                path: ROUTES.ADMIN.TRANSLATE_LANGUAGE_GROUPS,
                element: <><Header />
                    <div style={{ minHeight: `calc(100vh - 10vh)`, width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                            <TranslateGroups />
                        </div>
                    </div>
                    <Footer />
                </>,
            },
            {
                path: ROUTES.ADMIN.TRANSLATE_LANGUAGE_VARIANTS,
                element: <><Header />
                    <div style={{ minHeight: `calc(100vh - 10vh)`, width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                            <TranslateVariantsLanguage />
                        </div>
                    </div>
                    <Footer />
                </>,
            },

            {
                path: ROUTES.ADMIN.EDIT_TRANSLATE_PRODUCT_LANGUAGE,
                element: <><Header />
                    <div style={{ minHeight: `calc(100vh - 10vh)`, width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                            <EditTranslateProductLanguage />
                        </div>
                    </div>
                    <Footer />
                </>,
            },

            {
                path: ROUTES.ADMIN.PAYMENT_METHODS,
                element: <><Header />
                    <div style={{ minHeight: `calc(100vh - 10vh)`, width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                            <PaymentMethods />
                        </div>
                    </div>
                    <Footer />
                </>,
            },
            {
                path: ROUTES.ADMIN.PRODUCTS,
                element: <><Header />
                    <div style={{ minHeight: `calc(100vh - 10vh)`, width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                            <Products />
                        </div>
                    </div>
                    <Footer />
                </>,
            },
            {
                path: ROUTES.ADMIN.TASTE_PROFILES,
                element: <><Header />
                    <div style={{ minHeight: `calc(100vh - 10vh)`, width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                            <TasteProfiles />
                        </div>
                    </div>
                    <Footer />
                </>,
            },
            {
                path: ROUTES.ADMIN.ADMINS,
                element: <><Header />
                    <div style={{ minHeight: `calc(100vh - 10vh)`, width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                            <Admins />
                        </div>
                    </div>
                    <Footer />
                </>,
            },
            {
                path: ROUTES.ADMIN.CSS_TEMPLATE,
                element: <><Header />
                    <div style={{ minHeight: `calc(100vh - 10vh)`, width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                            <CssTemplate />
                        </div>
                    </div>
                    <Footer />
                </>,
            },
        ],
        // footer: <Footer />
    }
]

export const router = createBrowserRouter(routes);
