import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Favorite, OrderHistory, TasteProfile } from '../../../components/User';
import { getAllTranslations } from '../../../apis/common';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function User() {
    const [value, setValue] = React.useState(0);
    const [labels, setLabels] = React.useState([]);
    const [staticData, setStaticData] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true); // Loading state

    const fetchLabel = async () => {
        try {
            setIsLoading(true); // Start loading
            const storedLanguage = localStorage.getItem('selectedLanguage');
            const response = await getAllTranslations(storedLanguage);
            if (response.code === 200) {
                setLabels(response.result);
                setStaticData(response.fromPrice);
            }
        } catch (error) {
            console.error('Error fetching language data:', error);
        } finally {
            setIsLoading(false); // End loading
        }
    }

    React.useEffect(() => {
        const parts = window.location.pathname.split("/");
        const value = parseInt(parts[parts.length - 1]);
        setValue(value);
        fetchLabel();
    }, [])

    const getTranslatedLabel = (languageKey, defaultText) => {
        const languageItem = staticData.find((obj) => obj.language_key === languageKey);
        return languageItem ? languageItem.translated_language : defaultText;
      };
      

    React.useEffect(() => {
        const parts = window.location.pathname.split("/")
        const value = parseInt(parts[parts.length - 1])
        setValue(value);
        fetchLabel();
        console.log('USER PAGE Label:', labels);
        console.log('USER PAGE Static data:', staticData);
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '90%' }}>
            <Typography variant='h3' sx={{ marginTop: 5, alignSelf: "left", fontWeight: "bold", fontFamily: 'Clearface' }}>{getTranslatedLabel("profile", "Profile")}</Typography>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: 5 }}>
                <Tabs value={value} onChange={handleChange} aria-label="tabs">
                    <Tab label={getTranslatedLabel("taste_profile", "Taste Profile")} {...a11yProps(0)} />
                    <Tab label={getTranslatedLabel("order_history", "Order History")} {...a11yProps(1)} />
                    <Tab label={getTranslatedLabel("favorites", "Favorites")} {...a11yProps(2)} />
                </Tabs>
            </Box>
            {!isLoading && ( // Only render tabs and panels if not loading
            <>
            <TabPanel value={value} index={0}>
                <TasteProfile staticData={staticData} labels={labels} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <OrderHistory staticData={staticData} labels={labels} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Favorite staticData={staticData} labels={labels} />
            </TabPanel>
            </>
            )}            
        </Box>
    );
}