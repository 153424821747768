import React, { useEffect, useState } from 'react';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Card, CardHeader, CircularProgress, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './styles.scss'
import { addGroup, updateGroup } from '../../../apis/admin';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

function VariantGroupRow({ options, setOptions, index, deleteOption }) {
    const handleChange = (newValue, field) => {
        const optionsCopy = [...options];
        optionsCopy[index] = {
            ...optionsCopy[index],
            [field]: newValue
        };
        setOptions(optionsCopy);
    };

    return (
        <>
            {
                index === 0 ?
                    <div style={{ flex: 1, display: "flex", flexDirection: 'row', justifyContent: "space-between", alignItems: "center" }}>
                        <TextField
                            value={options[index].title}
                            margin="normal"
                            required
                            fullWidth
                            id="variant"
                            onChange={(e) => {
                                handleChange(e.target.value, 'title')
                            }}
                            label="Default variant"
                            name="variant"
                            autoComplete="variant"
                        />
                    </div> :
                    <div style={{ flex: 1, display: "flex", flexDirection: 'row', justifyContent: "space-between", alignItems: "center", gap: 5 }}>
                        <TextField
                            value={options[index].title}
                            margin="normal"
                            required
                            style={{ flex: 2 }}
                            fullWidth
                            id="variant"
                            onChange={(e) => {
                                handleChange(e.target.value, 'title')
                            }}
                            label={`Variant# ${index + 1}`}
                            name="variant"
                            autoComplete="variant"
                        />
                        <TextField
                            value={options[index].price}
                            margin="normal"
                            required
                            style={{ flex: 1 }}
                            fullWidth
                            id="price"
                            onChange={(e) => {
                                handleChange(e.target.value, 'price')
                            }}
                            label="Price"
                            name="price"
                            autoComplete="price"
                        />
                        <TextField
                            value={options[index].order}
                            margin="normal"
                            required
                            style={{ flex: 1 }}
                            fullWidth
                            id="order"
                            onChange={(e) => {
                                handleChange(e.target.value, 'order')
                            }}
                            label="Order"
                            name="order"
                            autoComplete="order"
                        />
                        {
                            index > 1 &&
                            <IconButton onClick={() => deleteOption(index)}>
                                <DeleteIcon htmlColor='red' />
                            </IconButton>
                        }
                    </div>
            }
        </>
    );
}

function GroupEditCard({ setData, data, closeBackdrop, setChange, change, setText, setOpenFailed, setOpenSucess }) {
    const [title, setTitle] = useState("");
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);

    const addOption = () => {
        setOptions([...options, {
            title: "",
            order: options.length + 1,
            price: 0
        }])
    }

    const deleteOption = (index) => {
        let arr1 = options.slice(0, index)
        let arr2 = options.slice(index + 1, options.length)
        setOptions(arr1.concat(arr2))
    }

    useEffect(() => {
        setTitle(data?.title || "");
        let variants = data?.options?.length ? data?.options : [
            {
                title: "",
                order: 1,
                price: 0
            },
            {
                title: "",
                order: 2,
                price: 0
            }
        ]
        console.log(variants);
        setOptions(variants)
    }, [data])

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data_ = new FormData(event.currentTarget);
        const updatedAddon = {
            id: data?.id,
            title: data_.get("title"),
        };

        if (data) {
            setLoading(true);
            const response = await updateGroup(updatedAddon.id, { title: updatedAddon.title, options });
            setLoading(false);
            if (response.code === 200) {
                setText("Variant group successfully updated!");
                setOpenSucess(true);
            } else {
                setText("There was an error while updating the variant group!");
                setOpenFailed(true);
            }
        } else {
            setLoading(true);
            const response = await addGroup({ title: updatedAddon.title, options });
            console.log(response);
            setLoading(false);
            if (response.code === 200) {
                setText("Variant group successfully added!");
                setOpenSucess(true);
            } else {
                setText("There was an error while adding the variant group!");
                setOpenFailed(true);
            }
        }

        setChange(!change);
        closeBackdrop();
        setData({});
        setOptions([])
    };

    return (
        <Card sx={{ padding: 2, width: "46%", maxHeight: "90%", overflowY: "scroll" }}>
            <CardHeader
                action={
                    <IconButton aria-label="settings"
                        onClick={() => { closeBackdrop(); setOptions([]) }}
                    >
                        <CloseIcon sx={{ alignSelf: "flex-end" }} />
                    </IconButton>
                }
                title={data ? "Edit Variant Group" : "Add new Variant Group"}
                sx={{ color: "black" }}
            />

            <Container component="main">
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <div style={{ flex: 1, display: "flex", flexDirection: 'row', justifyContent: "space-between", alignItems: "center" }}>
                        <TextField
                            value={title}
                            margin="normal"
                            required
                            fullWidth
                            id="title"
                            onChange={(e) => {
                                setTitle(e.target.value);
                            }}
                            label="Title"
                            name="title"
                            autoComplete="title"
                            autoFocus
                        />
                    </div>
                    {
                        options.map((option, index) => (
                            <VariantGroupRow options={options} setOptions={setOptions} index={index} deleteOption={deleteOption} />
                        ))
                    }
                    <Button sx={{ alignSelf: "flex-end" }} color="primary" startIcon={<AddIcon />} onClick={addOption}>
                        Add Option
                    </Button>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {data ? "Update Info" : "Add Variant Group"}
                        {
                            loading && <CircularProgress size={15} color="inherit" sx={{ ml: 1 }} />
                        }
                    </Button>
                </Box>
            </Container>
        </Card >
    );
}

export default GroupEditCard;
