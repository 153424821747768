import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Carousal from '../../../components/Home/Carousal';
import { useNavigate } from 'react-router-dom';
import { Backdrop, ImageListItemBar, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { getCategories } from '../../../apis';
import JoinUs from '../../../components/common/JoinUs';
import { useSelector } from 'react-redux';
import { getCategoryLabel, getLabel, getRecommended, getProductLabel } from '../../../apis/common';
import { Splash } from '../Splash';
import Header from '../../../components/common/Header';
import Footer from '../../../components/common/Footer';
import Cookies from 'js-cookie';


export default function Home() {
    const navigate = useNavigate();
    const [categories, setCategories] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [recommended, setRecommended] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [width, setWidth] = React.useState(window.innerWidth);
    const [isLanguageSelected, setIsLanguageSelected] = React.useState(false);
    const info = useSelector((store) => store.info);

    const [labels, setLabels] = React.useState([]);
    const [productLabels, setProductLabels] = React.useState([]);
    const [categoryLabels, setCategoryLabels] = React.useState([]);
    const [splashed, setSplashed] = React.useState(true);

    const getTranslatedLabel = (languageKey) => {
        const languageItem = labels.find((obj) => obj.language_key === languageKey);
        return languageItem ? languageItem.translated_language : '';
    };

    const getCatLabel = (languageKey) => {
        const languageItem = categoryLabels.find((obj) => obj.base_text === languageKey);
        return languageItem ? languageItem.text : '';
    };

    const fetchCategoryLabel = async () => {
        try {
            const storedLanguage = localStorage.getItem('selectedLanguage');
            if (storedLanguage) {
                const response = await getCategoryLabel(storedLanguage);
                if (response.code === 200) {
                    setCategoryLabels(response.result);
                }
            }
        } catch (error) {
            console.log('Error fetching language data:', error);
        }
    };

    React.useEffect(() => {

        // Extract table number from URL
        const urlParams = new URLSearchParams(window.location.search);
        const tableNumber = urlParams.get('table');

        // Store it to sessionStorage
        sessionStorage.setItem('tableNumber', tableNumber);
        fetchData();
        window.addEventListener('resize', updateDimension);
        fetchLabel()
        fetchCategoryLabel()
        console.log(Cookies.get('selectedLanguage'))
        setIsLanguageSelected(Cookies.get('selectedLanguage') ? true : false)
    }, [])

    async function fetchData() {
        setLoading(true);
        setCategories(await getCategories());
        setRecommended(await getRecommended())
        setLoading(false);
    }

    React.useEffect(() => {
        window.addEventListener('resize', updateDimension);
        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [width])

    const updateDimension = () => {
        setWidth(window.innerWidth);
    }

    const fetchLabel = async () => {
        try {
            const storedLanguage = localStorage.getItem('selectedLanguage');
            const response = await getLabel(storedLanguage || '');
            if (response.code === 200) {
                setLabels(response.result);
            }

            const responseProduct = await getProductLabel(storedLanguage || '');
            if (responseProduct.code === 200) {
                setProductLabels(responseProduct.result);
            }

        } catch (error) {
            console.log('Error fetching language data:', error);
        }
    };

    const toggleSplash = () => {
        setSplashed(!splashed)
        navigate(0);
    }

    return (

        !loading && !splashed || isLanguageSelected ?
            <>
                <Header />
                {/* < Box sx={{ boxShadow: 3, zIndex: 3, borderRadius: 2, width: "80%", mt: 5, padding: 5 }
                }>
                    <Typography variant='h3' sx={{ fontWeight: "bold", textAlign: 'center' }}>{getTranslatedLabel('welcome_to_personal_waiter')}</Typography>
                    <Typography sx={{ mt: 4, textAlign: "center", fontSize: 20 }}>{getTranslatedLabel('home_head_text')}</Typography>
                </Box > */}

                <Box sx={{ boxShadow: 3, zIndex: 3, borderRadius: 2, width: "80%", mt: 5, pt: 5, pb: 5 }} >
                    <Carousal labels={labels} recommended={recommended} productlabels={productLabels} />
                </Box>

                <Typography variant='h3' sx={{ marginTop: 10, fontWeight: "bold", fontFamily: 'Clearface' }}>{getTranslatedLabel('our_menu')}</Typography>

                {
                    loading && <CircularProgress color="inherit" sx={{ marginTop: 10, marginBottom: 100 }} />
                }

                {
                    loading ? <></> :
                        categories?.length === 0 ?
                            <Typography variant='h5' sx={{ textAlign: "center", marginTop: 10, marginBottom: 100, color: "text.secondary" }}>Menu is currently empty. Stay tuned!</Typography> :
                            <Box sx={{ marginTop: 5 }}>
                                <ImageList variant="masonry" cols={width <= '500px' ? 1 : width < 800 ? 2 : width < 900 ? 3 : 3} gap={8} >
                                    {
                                        categories?.map((item) => (
                                            <ImageListItem
                                                key={item.img}
                                                className="carousel__slide-item"
                                                onClick={() => { navigate(`/category/${item.title}/${item.category_id}`) }}
                                            >
                                                <div className="carousel__slide-item carousel__slide-item-img-link" style={{height: '150px', important: true}} >
                                                    <img
                                                        className="carousel__slide-item-img-link"
                                                        src={item.image}
                                                        alt={item.title}
                                                        loading="lazy"
                                                        style={{ transition: "all 0.5s ease" }}
                                                    />
                                                </div>
                                                <ImageListItemBar
                                                    sx={{
                                                        background:
                                                            'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                                            'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                                    }}
                                                    title={item.title ? getCatLabel(item.title) : ''}
                                                    position="top"
                                                    actionPosition="left"
                                                />
                                            </ImageListItem>
                                        ))}
                                </ImageList>
                            </Box>

                }
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                >
                    <JoinUs text={info.joinUsText} link={info.joinUsLink} setOpen={setOpen} image={info.joinUsImage} />
                </Backdrop>
                <Footer />
            </>
            : <Splash loading={loading} onPress={toggleSplash} />
    );
}
